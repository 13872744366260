import DataService from "./data";

const currentValueTypeCast = (data) => {
  if (!data || !data.current_value) return data;
  if (
    data?.current_value?.value !== "" &&
    !/[,]/.test(data.current_value.value) // reserved for vector
  ) {
    switch (data.type) {
      case "int":
      case "bool":
        data.current_value.value = parseInt(data.current_value.value);
        break;
      case "float":
        data.current_value.value = parseFloat(data.current_value.value);
        break;
    }
  }
  return data;
};

const defFormat = {
  bool: "",
  string: "%s",
  float: "%.2f",
  int: "%d"
};

export {currentValueTypeCast, defFormat};

export default class EquipmentDataService {
  // The $vm can`t be refered before application mounted hook has been already called
  $vm = window?.app?.__vue__?.$root || null;
  $proxy = new DataService();

  memoryTypeList(data) {
    let protocol_id = data?.device?.connector?.protocol?.id;
    if (!this.$vm || !protocol_id) return [];
    let ls,
      lst = this.$vm?.config?.references?.data_memory_types || [];

    return data.is_local
      ? lst
      : lst.filter(({allowed_protocols}) => {
          ls = (allowed_protocols || []).filter((p) => p.id == protocol_id);
          return ls.length > 0;
        });
  }

  memoryType(data) {
    return (
      (data?.memory_type?.id &&
        (this.memoryTypeList(data) || []).find(
          ({id}) => id == data?.memory_type?.id
        )) ||
      null
    );
  }

  basicType(data) {
    return this.memoryType(data)?.basic_type || null;
  }

  dataAdapter(payload) {
    if (!payload) return;
    let lst = payload.id ? [payload] : payload.length ? payload : [];
    (lst || []).forEach((data) => {
      // remove extra dots from name attribute
      data.name = data.name.replace(/\./g, "");
      if (data.device) {
        data.device.name = data.device.name.replace(/\./g, "");
        if (data.device.connector) {
          data.device.connector.name = data.device.connector.name.replace(
            /\./g,
            ""
          );
        }
      }
      // API V0 - backward compatibility
      // published screens might been still using it on production since rest api v0
      // clp_id=connector_id - backward compatibility issues with reports from API V0
      data.nome = data.name || "";
      data.clp_id =
        (data.device && data.device.connector && data.device.connector.id) || 0;

      // type casting
      data.type = this.basicType(data)?.type || "float"; // default
      if (data.type == "int" && data.calculation_type) {
        data.type = "float";
      }
      currentValueTypeCast(data);

      // this object allow us to restore the data value if an action has changed it
      data.restore =
        (data.current_value &&
          JSON.parse(JSON.stringify(data.current_value))) ||
        null;

      // the read_only flag is sometimes inconsistent and might be true, even for read_write_mode != 0
      if (
        data.read_only &&
        !data.is_local &&
        data.read_write_mode !== undefined &&
        data.read_write_mode !== 0
      ) {
        data.read_only = false;
      }
    });
    return lst;
  }

  async fetch(query) {
    return new Promise((resolve) => {
      this.$proxy.fetch(query).then(
        (response) => {
          this.dataAdapter(response);
          resolve(response);
        },
        (error) => {
          resolve(null);
          throw error;
        }
      );
    });
  }

  // delegated methods:
  history = this.$proxy.history;
  save = this.$proxy.save;
  get = this.$proxy.get;
  remove = this.$proxy.remove;
  export = this.$proxy.export;
  import = this.$proxy.import;
  duplicate = this.$proxy.duplicate;
  removeMultiple = this.$proxy.removeMultiple;
  fetchSamples = this.$proxy.fetchSamples;
  saveSamples = this.$proxy.saveSamples;
  history = this.$proxy.history;
  checkTask = this.$proxy.checkTask;
}
