import Vue from "vue";
import {groupBy, isEqual, uniq, uniqBy, debounce} from "lodash";
import DataService from "@/services/data";
import Stats from "@/utils/stats.js";
import {uuid, isTrue, JSEvaluation} from "@/plugins/utils.js";
let _Stats = new Stats();
const stats = (samples, fname) => _Stats && _Stats.calc(samples, fname);

// original https://github.com/BorisChumichev/everpolate/blob/master/lib/linear.js
const interpolate = (x, x0, y0, x1, y1) => {
  var a = (y1 - y0) / (x1 - x0);
  var b = -a * x0 + y0;
  return a * x + b;
};

const findIntervalBorderIndex = (point, intervals, useRightBorder) => {
  if (point < intervals[0]) return 0;
  if (point > intervals[intervals.length - 1]) return intervals.length - 1;
  var indexOfNumberToCompare,
    leftBorderIndex = 0,
    rightBorderIndex = intervals.length - 1;
  while (rightBorderIndex - leftBorderIndex !== 1) {
    indexOfNumberToCompare =
      leftBorderIndex + Math.floor((rightBorderIndex - leftBorderIndex) / 2);
    point >= intervals[indexOfNumberToCompare]
      ? (leftBorderIndex = indexOfNumberToCompare)
      : (rightBorderIndex = indexOfNumberToCompare);
  }
  return useRightBorder ? rightBorderIndex : leftBorderIndex;
};

// create a new array with extrapolated points
const linearInterpolation = (
  pointsToEvaluate,
  functionValuesX,
  functionValuesY
) => {
  if (pointsToEvaluate.length <= 2) return functionValuesY;
  var results = [];
  (pointsToEvaluate || []).forEach((point) => {
    var index = findIntervalBorderIndex(point, functionValuesX);
    if (index == functionValuesX.length - 1) index--;
    results.push(
      interpolate(
        point,
        functionValuesX[index],
        functionValuesY[index],
        functionValuesX[index + 1],
        functionValuesY[index + 1]
      )
    );
  });
  return results;
};

// create a new array filling with empty values the last value
const fillList = (refLst, lst, leave_them_empty) => {
  if (!refLst.length || !lst.length || refLst.length < lst.length) return lst;
  let result = [];
  let keyAttr = refLst[0].key ? "key" : "time";
  let data_id = lst[0].data_id;
  let lst_cpy = [...lst];
  refLst.forEach((ref, i) => {
    // let sample = defSample(data_id, ref.time, ref.date_time);
    let sample = {
      data_id: data_id,
      value: undefined
    };
    sample[keyAttr] = ref[keyAttr];
    if (ref.date_time) sample.date_time = ref.date_time;
    if (keyAttr == "time") {
      while (lst_cpy.length && ref.time >= lst_cpy[0].time)
        sample.value = lst_cpy.shift().value;
    } else {
      sample.value = lst_cpy.find(({key}) => key == ref.key)?.value;
    }
    if (sample.value === undefined) {
      sample.value =
        !leave_them_empty && result.length
          ? result[result.length - 1].value
          : "";
    }
    result.push(sample);
  });
  return result;
};

const initHistoryData = () => {
  return {
    samples: [],
    stats: (_Stats || new Stats()).calc([])
  };
};

const generate = (ids, samples) => {
  let entries = {};
  let grouped = groupBy(samples, "data_id");
  const stats = _Stats || new Stats();
  ids.forEach((id) => {
    entries[id] = initHistoryData();
    if (id in grouped) {
      entries[id].samples = grouped[id];
      entries[id].stats = stats.calc(entries[id].samples);
    }
  });
  return entries;
};

const buildQuery = (ids, start, end) => ({
  data_ids: (ids && ids.join(",")) || "",
  start:
    (start &&
      start
        .clone()
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss")) ||
    null,
  end:
    (end &&
      end
        .clone()
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss")) ||
    null
});

const tsFilter = (context, samples) => {
  if (!context.state.filter.data_id) return samples;
  let values = (context.state.filter.values || [])
    .map((i) => `${i}`)
    .filter((i) => i != "");
  if (!values.length) return context.state.filter.isRequired ? [] : samples;
  let ts = groupBy(samples, "date_time");
  let chk = false;
  let lst = [];
  for (var k in ts) {
    chk = ts[k].some(
      ({data_id, restore_value}) =>
        parseInt(data_id) == context.state.filter.data_id &&
        values.indexOf(restore_value) >= 0
    );
    if (chk) {
      lst = lst.concat(ts[k]);
    }
  }
  return lst;
};

// store
const initialState = () => ({
  interval: null,
  createdAt: "",
  entries: {},
  process: "idle",
  pending: [],
  ready: {},
  simulationDataList: [],
  filter: {
    data_id: "",
    isRequired: true,
    values: []
  },
  // aggregation
  namedQuery: null
});

const mutations = {
  RESET(state) {
    let s = initialState();
    delete s.filter;
    delete s.namedQuery;
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_INTERVAL(state, entry) {
    let interval = null;
    if (entry) {
      interval = {
        start: entry.start ? entry.start.clone().set("second", 0) : null,
        end: entry.end ? entry.end.clone().set("second", 59) : null
      };
    }
    Vue.set(state, "interval", interval);
    if (_Stats) _Stats = null;
    _Stats = new Stats();
  },
  SET_CREATED_AT(state, entry) {
    state.createdAt = entry;
  },
  SET_ENTRIES(state, entry) {
    Object.keys(entry || {}).forEach((id) => {
      Vue.set(state.entries, id, entry[id]);
    });
  },
  SET_PENDING(state, entry) {
    let lst = JSON.parse(JSON.stringify(state.pending));
    entry.ids.forEach((id) => {
      if (!entry.del) {
        lst.filter((i) => i != id);
        lst.push(id);
        Vue.set(state.ready, id, false);
      } else {
        lst = lst.filter((i) => i != id);
        Vue.set(state.ready, id, true);
      }
    });
    // remove duplicated ones
    state.pending = lst.filter((v, x, l) => l.indexOf(v) === x);
  },
  TOGGLE_SIMULATION_DATA(state, dataId) {
    let lst = state.simulationDataList || [];
    let i = lst.indexOf(dataId);
    if (i >= 0) {
      lst.splice(i, 1);
    } else {
      lst.push(dataId);
    }
    Vue.set(state, "simulationDataList", lst);
  },
  RESET_DATA(state, dataId) {
    Vue.set(
      state,
      "simulationDataList",
      (state.simulationDataList || []).filter((id) => id != dataId)
    );
    Vue.set(
      state,
      "pending",
      (state.pending || []).filter((id) => id != dataId)
    );
    Vue.delete(state.entries, dataId);
  },
  SET_FILTER_VALUES(state, value) {
    state.filter.values = value && value.length ? value : [];
  },
  SET_FILTER_DATA_ID(state, value) {
    state.filter.data_id = value;
  },
  SET_FILTER(state, value) {
    Vue.set(state, "filter", {...initialState().filter, ...value});
  },
  SET_NAMED_QUERY(state, value) {
    Vue.set(state, "namedQuery", JSON.parse(JSON.stringify(value)));
  }
};

const actions = {
  setInterval(context, entry) {
    context.state.api_samples = null;
    context.commit("SET_INTERVAL", entry);
  },
  fetch(context, dataIdList) {
    if (!context.state.interval) return;

    // remove duplicated ones
    let ids = (dataIdList || [])
      .filter((v, x, l) => l.indexOf(parseInt(v)) === parseInt(x))
      .map((id) => parseInt(id));
    // if any item from the list is already pending remove it from the list
    if (context.state.pending.length) {
      ids = ids.filter((id) => context.state.pending.indexOf(id) == -1);
    }
    if (!ids.length) return; // empty list provided or they are alredy being fetching
    // if there is a filter data_id add it
    if (context.state.filter.data_id) {
      ids.push(context.state.filter.data_id);
    }
    context.commit("SET_PENDING", {ids: ids});
    context.state.delayedFetch =
      context.state.delayedFetch ||
      debounce(() => {
        let ids = context.state.pending;
        let query = buildQuery(
          ids,
          context.state.interval.start,
          context.state.interval.end
        );
        context.state.query = query; // not reactive
        var srv = new DataService();
        srv.history(query).then((samples) => {
          let entries;
          if (typeof samples == "object") {
            if (samples.length && !samples[0].time && samples[0].date_time) {
              (_Stats || new Stats()).addVirtualProperties(samples);
            }
            context.state.api_samples = {
              ...(context.state.api_samples || {}),
              ...groupBy(samples || [], "data_id")
            };
            for (var data_id in context.state.api_samples || {}) {
              context.state.api_samples[data_id] = uniqBy(
                context.state.api_samples[data_id],
                "date_time"
              );
            }
            if (context.state.filter.data_id) {
              context.state.samples = samples; // it is not reactive
              samples = tsFilter(context, samples);
            }
            // it is not reactive
            context.commit("SET_CREATED_AT", moment());
            entries = generate(ids, samples);
            context.commit("SET_ENTRIES", entries);
          }
          context.commit("SET_PENDING", {ids: ids, del: true});
        });
      }, 1000);
    context.state.delayedFetch();
  },
  fetchFitInTimeWindow(context, dataIdList) {
    if (!context.state.interval) {
      return;
    }
    // remove duplicated ones
    let ids = (dataIdList || []).filter((v, x, l) => l.indexOf(v) === x);
    // if any item from the list is already pending remove it from the list
    if (context.state.pending.length) {
      ids = ids.filter((id) => context.state.pending.indexOf(id) == -1);
    }
    if (!ids.length) return; // empty list provided or they are alredy being fetching
    let query = buildQuery(
      ids,
      context.state.interval.start,
      context.state.interval.end
    );
    context.commit("SET_PENDING", {ids: ids});
    let srv = new DataService();
    let entries;
    srv.history(query, true).then((samples) => {
      context.commit("SET_CREATED_AT", moment());
      entries = generate(ids, samples);
      context.commit("SET_ENTRIES", entries);
      context.commit("SET_PENDING", {ids: ids, del: true});
    });
  },
  simulate(context, dataId) {
    context.commit("TOGGLE_SIMULATION_DATA", dataId);
    // remove duplicated ones
    let ids = (context.state.simulationDataList || []).filter(
      (v, x, l) => l.indexOf(v) === x
    );
    // if any item from the list is already pending remove it from the list
    if (context.state.pending.length) {
      ids = ids.filter((id) => context.state.pending.indexOf(id) == -1);
    }
    if (!ids.length) {
      for (const id in context.state.entries || {}) {
        ids.push(id);
      }
      context.commit("SET_PENDING", {ids: ids});
      setTimeout(() => {
        context.commit("RESET");
        // context.commit("SET_PENDING", { ids: ids, del: true });
      }, 0);
      return;
    }
    let hasNew = false;
    let now = moment();
    let interval = {
      start: moment()
        .subtract(2, "days")
        .startOf("day"),
      end: moment()
    };
    let diffMin = interval.end.diff(interval.start, "minutes");
    let samples = [];
    const rnd = (min, max) =>
      Math.floor(
        Math.random() *
          (((max ?? "") === "" ? 100 : max) -
            ((min ?? "") === "" ? 0 : min) +
            1) +
          ((min ?? "") === "" ? 0 : min)
      );
    ids.forEach((id) => {
      var data = (context.rootGetters["dashboard/dataList"] || []).find(
        (item) => item.id == id
      );
      let type = data.type;
      // if (
      //   data &&
      //   (data?.memory_type?.name || "").match(/(bool|coil|input status)/gi) !=
      //   null
      // ) {
      //   type = "boolean";
      // }
      if (
        id in context.state.entries &&
        (context.state.entries[id]?.samples || []).length
      ) {
        samples = samples.concat(context.state.entries[id].samples);
      } else {
        let nSamples = Math.floor(Math.random() * 100) + 1;
        let dt = interval.start;
        var status = 0;
        var statusCounter = 4;
        if (nSamples < statusCounter * 2) nSamples = statusCounter * 2;
        for (var i = 0; i <= nSamples; i++) {
          var value =
            type == "bool"
              ? status
              : type == "string"
              ? uuid()
              : rnd(data.minimum_value, data.maximum_value);
          if (statusCounter == 0) {
            status = status ? 0 : 1;
            statusCounter = parseInt(Math.random() * 5);
          } else {
            statusCounter -= 1;
          }
          samples.push({
            data_id: id,
            date_time: dt.toISOString(),
            value: value
          });
          dt = dt.add(diffMin / nSamples, "minutes");
        }
        hasNew = true;
      }
    });
    if (hasNew) {
      context.commit("SET_INTERVAL", interval);
      context.commit("SET_PENDING", {ids: ids});
      let entries;
      setTimeout(() => {
        context.commit("SET_CREATED_AT", now);
        entries = generate(ids, samples);
        context.commit("SET_ENTRIES", entries);
        context.commit("SET_PENDING", {ids: ids, del: true});
      }, 0);
    }
  },
  resetData(context, dataId) {
    context.commit("SET_PENDING", {ids: [dataId]});
    setTimeout(() => {
      context.commit("RESET_DATA", dataId);
    }, 0);
  },
  reset(context) {
    context.commit("SET_PENDING", {
      ids: Object.keys(context.state.entries)
    });
    setTimeout(() => {
      context.commit("RESET");
    }, 0);
  },
  setFilterValues(context, value) {
    let ids = Object.keys(context.state.entries || {});
    if (!ids.length && context?.state?.query?.data_ids) {
      ids = (context.state.query.data_ids || "").split(",");
    }
    if (!ids.length) return;
    let samples = Object.values(context.state.api_samples || {}).reduce(
      (a, c) => a.concat(c),
      []
    );
    // let samples = context.state.samples;
    if (context.state.filter.data_id) {
      context.commit("SET_FILTER_VALUES", value);
      samples = tsFilter(context, context.state.samples);
    } else {
      context.commit("SET_FILTER_VALUES", []);
    }
    context.commit("SET_PENDING", {ids: ids});
    setTimeout(
      () => {
        context.commit("SET_CREATED_AT", moment());
        let entries = generate(ids, samples);
        context.commit("SET_ENTRIES", entries);
        context.commit("SET_PENDING", {ids: ids, del: true});
      },
      10,
      this
    );
  },
  setFilter(context, value) {
    context.commit("SET_FILTER", value);
  },
  setNamedQuery(context, value) {
    context.commit("SET_NAMED_QUERY", value);
  }
};

const getters = {
  interval(state) {
    return state.interval;
  },
  createdAt(state) {
    return state.createdAt;
  },
  dataList(state) {
    return (state.dataList || []).map((data) => {
      let item = JSON.parse(JSON.stringify(data));
      item.history = (state.entries || {})[item.id];
      return item;
    });
  },
  entries(state) {
    return state.entries;
  },
  aggregatedEntries(state, getters) {
    let history = getters.entries || null;
    if (!history || !getters.groupByExpression || !getters?.columns?.length)
      return history;
    let dataId,
      groups,
      samples,
      stats,
      entries = {};
    const filterExpression = getters.filterExpression || "";
    const ord =
      (getters.groupByOrder || "") == "desc" ? [-1, 1, 0] : [1, -1, 0];
    const _stats = _Stats || new Stats();
    // todo: since columns can be repeated, it must be indexed by aggregation functions
    (getters.columns || []).forEach(
      ({data_id, aggregationFunctionName}, ix) => {
        dataId = parseInt(data_id);
        entries[ix] = initHistoryData(); // TODO: if should be indexed by ix or name instead
        samples = (history[dataId] || {})?.samples || [];
        if (!samples.length) return;
        groups = groupBy(
          (filterExpression &&
            samples.filter((i) =>
              isTrue(filterExpression, {...i, $value: i.value})
            )) ||
            samples,
          (i) => JSEvaluation(null, getters.groupByExpression, i)
        );
        for (let key in groups) {
          stats = _stats.calc(groups[key]);
          if (
            aggregationFunctionName &&
            _stats.agreggationFunctions[aggregationFunctionName]
          ) {
            entries[ix].samples.push({
              data_id: parseInt(dataId),
              key: key,
              value: stats[aggregationFunctionName],
              time: stats.lastSampleAt
            });
          }
        }
        if (entries[ix]) {
          entries[ix].data_id = dataId;
          entries[ix].stats = _stats.calc(entries[ix].samples);
          // attention: do not sort it before call stats.calc
          entries[ix].samples.sort((a, b) =>
            a.key > b.key ? ord[0] : a.key < b.key ? ord[1] : ord[2]
          );
        }
      }
    );
    return entries;
  },
  pending(state) {
    return state.pending;
  },
  ready(state) {
    return state?.ready || {};
  },
  defHistoryData() {
    return initHistoryData();
  },
  busy(state) {
    return (state?.pending || []).length > 0;
  },
  filterOptions(state) {
    if (!state.filter.data_id || !state.createdAt) return [];
    return uniq(
      (state.samples || [])
        .filter(({data_id}) => parseInt(data_id) == state.filter.data_id)
        .map(({restore_value}) => restore_value)
    ).sort();
  },
  filterValues(state) {
    return state.filter.values || [];
  },
  namedQueryName(state) {
    return state?.namedQuery?.name || "";
  },
  groupByExpression(state) {
    return state?.namedQuery?.groupByExpression || "";
  },
  groupByOrder(state) {
    return state?.namedQuery?.groupByOrder || "asc";
  },
  filterExpression(state) {
    return state?.namedQuery?.filterExpression || "";
  },
  columns(state) {
    return state?.namedQuery?.dataList || [];
  }
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

const createHistory = ($store, namespace) => {
  if (!$store.hasModule(namespace)) {
    $store.registerModule(namespace, {
      namespaced: true,
      state: initialState(),
      mutations: mutations,
      actions: actions,
      getters: getters
    });
  }
};

const destroyHistory = ($store, namespace) => {
  if ($store.hasModule(namespace)) {
    $store.unregisterModule(namespace);
  }
};
export {fillList, linearInterpolation, createHistory, destroyHistory, stats};
