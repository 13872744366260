import Vue from "vue";

import DashboardService from "../services/dashboard";
import ScreenService from "@/services/screen.js";
import ConnectorService from "@/services/connector.js";
import EquipmentService from "@/services/equipment.js";
import EquipmentDataService from "@/services/equipment-data.js";
import DeviceService from "@/services/device.js";
import DataService from "@/services/data.js";
import AlarmService from "@/services/alarm.js";
import Panels from "@/assets/dashboard/panels.json";
import Screen from "@/assets/dashboard/screen.json";

import {isEqual, omit} from "lodash";
import {pipe} from "@/utils";
import {
  getUnpublishedList,
  nextId,
  IndexTableDrafts,
  removeFromLocalStorage,
  editorSettings,
  draftDB,
  panelMerge
} from "../services/dashboard";

import {deviceListAdapter, deviceAdapter} from "@/services/device.js";
import {currentValueTypeCast} from "@/services/equipment-data.js";

const RESOURCE_SERVICE_MAP = {
  connector: ConnectorService,
  device: DeviceService,
  data: DataService,
  alarm: AlarmService
};

const RESOURCE_STATE_MAP = {
  connector: "connectorList",
  device: "descendent",
  data: "descendent",
  alarm: "descendent"
};

const RESOURCE_ROUTINE_MAP = {
  connector: [equipmentAdapter],
  device: [devAdapter], //[updateConnectorDevices],
  data: [dataAdapter, updateDevices]
};

function equipmentAdapter({result: connectors, context: {getters}}) {
  let connService = new ConnectorService();
  let equipService = new EquipmentService();
  connectors = connectors.map((connector) => {
    connector = equipService.equipmentAdapter(connector, connService);
    let extendedProperties = equipService.getExtendedProperties(connector);
    Object.assign(connector, extendedProperties);
    let existingConnector = getters.connectorList?.find?.(
      ({id}) => id == connector.id
    );
    if (existingConnector?.devices) {
      connector.devices = existingConnector.devices;
    }
    return connector;
  });
  return {...arguments[0], result: connectors};
}

function dataAdapter({result: dataList}) {
  let service = new EquipmentDataService();
  service.dataAdapter(dataList);
  return {...arguments[0], result: dataList};
}

function devAdapter({result: deviceList}) {
  deviceList = deviceList.map((item) => deviceAdapter(item));
  return {...arguments[0], result: deviceList};
}
function updateConnectorDevices({
  result: deviceList,
  context: {getters, commit}
}) {
  let list = {};
  deviceList.forEach((device) => {
    let existingConnector =
      list[device.connector.id] ??
      getters.connectorList?.find?.(({id}) => id == device.connector.id);
    if (existingConnector) {
      let devices = JSON.parse(JSON.stringify(existingConnector.devices ?? []));
      let index = devices.findIndex(({id}) => id == device.id);
      if (index > -1) {
        devices[index] = device;
      } else {
        devices.push(device);
      }
      list[existingConnector.id] = {...existingConnector, devices};
    }
  });
  if (Object.keys(list).length)
    commit("ADD_RESOURCE", {
      resource: "connector",
      list: Object.entries(list).map(([, v]) => v),
      forceUpdate: true
    });
  return arguments[0];
}

function updateDevices({
  result: dataList,
  context: {getters, commit},
  options: {update}
}) {
  if (update == "device") {
    dataList.forEach(({device}) => {
      let devices = JSON.parse(JSON.stringify(getters.deviceList));
      let index = devices.findIndex(({id}) => id == device.id);
      if (index > -1) {
        devices[index] = device;
      } else {
        devices.push(device);
      }
      commit("ADD_RESOURCE", {
        resource: "device",
        list: devices,
        forceUpdate: true
      });
    });
  }
  return arguments[0];
}

/*
Single localstorage crud for any dashboard related operation
get    : _dashboard = (id)
save   : _dashboard = (id, payload)
remove : _dashboard = (id, null)
*/
const _draft = draftDB;
const _dashboardService = new DashboardService();
import {panelPosition} from "@/services/dashboard.js";
function initialState() {
  return {
    isLoading: false,
    isReady: false,
    config: null,
    isLoadingTemplate: false,
    templates: {},
    screens: null,
    screensEtags: {},
    expandedPanel: "",
    fullscreenPanel: "",
    editorPanelName: "",
    draft: null,
    mode: "viewer",
    sidebar: null,
    currentDraftPanel: null,
    connectorList: null,
    connectorsResources: {},
    tasks: [],
    dashboardEquipmentId: null, // connector id of currently open dashboard
    dashboardScreenId: null, // screen id of currently open dashboard
    sessionId: null,
    clipboard: {
      panel: null,
      screenId: null
    },
    listUpdateCounter: 0,
    settingUpdateCounter: 0,
    applicableStyle: null,
    manualRefresh: false,
    controlDataSelectorSource: null,
    dataValueSync: 0,
    connectorValueSync: 0,
    dataDisplayLabel: null,
    showDeletedScreens: null,
    dragging: "",
    simulation: false,
    dirtyForms: {}
  };
}
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    RESET(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    SET_CONFIG(state, option) {
      state.config = option;
    },
    IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    IS_READY(state, opt) {
      state.isReady = opt;
    },
    SET_TEMPLATE(state, entry) {
      var templates = state.templates || {};
      if (entry.data) {
        templates[entry.id] = JSON.parse(JSON.stringify(entry.data));
        Vue.set(state, "templates", templates);
      }
    },
    DEL_TEMPLATE(state, id) {
      var templates = state.templates || {};
      if (templates && id in templates) {
        delete templates[id];
        Vue.set(state, "templates", templates);
      }
    },
    SET_LOADING_TEMPLATE(state, option) {
      Vue.set(state, "isLoadingTemplate", option);
    },
    SET_EXPANDED_PANEL(state, panelName) {
      state.expandedPanel = panelName;
    },
    SET_FULLSCREEN_PANEL(state, panelName) {
      state.fullscreenPanel = panelName;
    },
    SET_SCREENS(state, screens) {
      Vue.set(state, "screens", screens);
    },
    SET_SCREEN(state, value) {
      let screen = JSON.parse(JSON.stringify(value));
      let ix = (state.screens || []).findIndex((i) => screen.id == i.id);
      if (ix >= 0) {
        Vue.set(state.screens, ix, screen);
      } else {
        state.screens = (state.screens || []).concat(screen);
      }
    },
    DEL_SCREEN(state, screenId) {
      let screens = state.screens || [];
      let ix = (screens || []).findIndex((i) => screenId == i.id);
      if (ix >= 0) {
        if (screens[ix].deleted_at || screens[ix].id < 0) {
          screens.splice(ix, 1);
        } else {
          screens[ix].deleted_at = new Date().toISOString();
        }
        Vue.set(state, "screens", screens);
      }
      if (screenId in (state.templates || {})) {
        delete state.templates[screenId];
        Vue.set(state, "templates", state.templates);
      }
      let key = `dashboard(${screenId})/layout`;
      localStorage.removeItem(key);
    },
    SET_EDITOR_PANEL(state, entry) {
      if (entry) {
        state.editorPanelName = entry.panelName;
      } else {
        state.editorPanelName = null;
      }
    },
    SET_MODE(state, mode) {
      if (["editor", "viewer"].indexOf(mode) < 0) {
        mode = "viewer";
      }
      state.mode = mode;
      if (mode == "viewer") {
        state.editorPanelName = null;
        state.expandedPanel = null;
        state.fullscreenPanel = null;
      } else {
        state.settingUpdateCounter += 1;
      }
    },
    SAVE_DRAFT(state, entry) {
      if (entry && entry.screenId) {
        let screen = (state.screens || []).find((i) => i.id == entry.screenId);
        if (entry.template) {
          if (!entry?.template?.draft?.etag && screen?.etag) {
            entry.template.draft = entry.template.draft || {};
            entry.template.draft.etag = screen.etag;
          }
          if (!entry?.template?.draft?.tags && screen?.portal_data?.tags) {
            entry.template.draft = entry.template.draft || {};
            entry.template.draft.tags = screen?.portal_data?.tags;
          }

          if (entry.screenId in state.templates) {
            state.templates[entry.screenId].draft =
              state.templates[entry.screenId].draft || {};
            state.templates[entry.screenId].draft.tags =
              entry?.template?.draft?.tags || [];
          }
          Vue.set(state, "draft", entry);
          if (screen && !screen.public) {
            entry.template.draft.updated_at = new Date().getTime();
            _draft(entry.screenId, entry.template);
          }
        } else {
          Vue.set(state, "draft", null);
          if (screen && !screen.public) {
            _draft(entry.screenId, null);
          }
        }
      }
    },
    RESET_DRAFT(state) {
      Vue.set(state, "draft", null);
      // Dnly reset it (do not remove the localStorage)
    },
    SAVE_DRAFT_PANEL(state, entry) {
      if (
        state.draft &&
        state.draft.screenId == entry.screenId &&
        entry.panel
      ) {
        let dashboard = state.draft.template;
        dashboard.panels = dashboard.panels || [];
        let pos = (dashboard.panels || []).findIndex(
          (i) => i.name == entry.panel.name
        );
        if (pos >= 0) {
          dashboard.panels[pos] = entry.panel;
          if (entry.setAsCurrent) {
            state.currentDraftPanel = entry.panel;
          }
          _draft(entry.screenId, dashboard);
        }
      }
    },
    SET_EDITOR_SCREEN_ID(state, id) {
      if (state.draft) {
        state.draft.screenId = id;
      }
    },
    CREATE(state, entry) {
      let screenId = parseInt(entry.screenId || 0);
      let id = parseInt(screenId || nextId());
      let screens = state.screens;
      let template = null;
      let templates = state.templates || {};
      let screen = {
        id: id,
        contract_id: "",
        description: "",
        name: "",
        path: "",
        public: false,
        revision_code: "",
        revision_comment: "",
        portal_data: {
          tags: []
        }
      };
      screens.push(screen);
      if (!(id in state.templates)) {
        template = _draft(id);
        if (!template) {
          if (entry.template) {
            template = JSON.parse(JSON.stringify(entry.template));
          } else {
            template = JSON.parse(JSON.stringify(Screen));
          }
        }
        templates[id] = template;
      } else {
        template = state.templates[id];
      }
      screen.portal_data.tags = (template && template?.draft?.tags) || [];
      Vue.set(state, "screens", screens);
      Vue.set(state, "templates", templates);
      // return by reference
      entry.screenId = id;
      entry.template = template;
    },
    SET_SIDEBAR(state, value) {
      if (value) {
        Vue.set(state, "sidebar", {...value});
      } else {
        Vue.set(state, "sidebar", null);
      }
    },
    SET_CURRENT_DRAFT_PANEL(state, value) {
      state.currentDraftPanel = value;
    },
    ADD_TASK(state, task) {
      state.tasks.push({
        ...task,
        done: false,
        id: state.sessionId,
        rerun: task.once
          ? () => {}
          : function(dispatch) {
              if (typeof dispatch !== "function") return;
              if (this.query instanceof Array)
                return dispatch(
                  "dashboard/fetchResources",
                  {
                    resource: this.resource,
                    list: this.query,
                    forceUpdate: true
                  },
                  {root: true}
                );
              else
                return dispatch(
                  "dashboard/fetchResourcesFrom",
                  {
                    resource: this.resource,
                    connectorId: this.query,
                    forceUpdate: true,
                    ...this.filters
                  },
                  {root: true}
                );
            }
      });
    },
    FINISH_TASK(state, task) {
      // TODO: unfortunately it can not remove the run once tasks, since hasResourceFrom trust on tasks to flag
      // remove the run_once task (if there is one)
      // let runOnceTaskPos = state.tasks.findIndex(
      //   (item) =>
      //     item.resource == task.resource &&
      //     (item.query instanceof Array
      //       ? isEqual(item.query.sort(), task.query?.sort?.())
      //       : item.query == task.query) &&
      //     isEqual(item.filters, task.filters) &&
      //     item.once
      // );
      // if (runOnceTaskPos >= 0) {
      //   state.tasks.splice(runOnceTaskPos, 1);
      //   return;
      // }
      // continue ...
      // todo: maz - review it since tasks might remains untouched (lack of task id)
      let pendingTask = state.tasks.find(
        ({resource, query, filters}) =>
          resource == task.resource &&
          (query instanceof Array
            ? isEqual(query.sort(), task.query?.sort?.())
            : query == task.query) &&
          isEqual(filters, task.filters)
      );
      if (pendingTask) {
        pendingTask.done = true;
      }
    },
    ADD_RESOURCE(
      state,
      {resource, list, connectorId, forceUpdate, hasFilters}
    ) {
      if (!(resource in RESOURCE_STATE_MAP)) return;
      // create an empty object for given connector if it doesn't exist yet
      if (!state.connectorsResources[connectorId] && connectorId)
        Vue.set(state.connectorsResources, connectorId, {});
      // if it's a resource descendent of a connector (device, data or alarm)
      if (RESOURCE_STATE_MAP[resource] == "descendent") {
        // if the resource connector is known (fetched with fetchResourcesFrom)
        if (connectorId) {
          let currentList =
            state.connectorsResources[connectorId][resource + "List"] ?? [];
          let newList = currentList;
          if (!hasFilters && forceUpdate) {
            // if forceUpdate is true and
            // no filters was used, replace entire list
            newList = list;
          } else {
            // if forceUpdate is true, resources
            // already in the list will be updated
            if (forceUpdate) {
              newList = currentList.map(
                (item) => list.find(({id}) => id == item.id) || item
              );
              // var changed = false;
              // newList = currentList.map((o) => {
              //   let n = list.find(({ id }) => id == o.id) || o;
              //   if (n.etag == o.etag) {
              //     return o;
              //   } else {
              //     changed = true;
              //     return n;
              //   }
              // });
              // if (!changed) return;
            }
            // filter duplicated
            list = list.filter(
              (item) => !currentList.some(({id}) => id == item.id)
            );
            // adds new resources to the list
            newList = newList.concat(list);
          }
          Vue.set(
            state.connectorsResources[connectorId],
            resource + "List",
            newList
          );
          if (forceUpdate && resource == "device" && newList.length) {
            let dataList =
              state.connectorsResources[connectorId]["dataList"] || [];
            if (dataList.length) {
              let devices = structuredClone(newList);
              dataList.forEach((data) => {
                (devices || []).forEach((device) => {
                  if (
                    !device.etag ||
                    !data.device ||
                    (parseInt(data?.device?.id) !== parseInt(device.id) &&
                      parseInt(data?.device_id) !== parseInt(device.id))
                  )
                    return;
                  Vue.set(data, "device", device);
                });
              });
            }
            if (newList.some(({device_status}) => device_status.number == 1)) {
              this.commit("dashboard/SET_CONNECTOR_VALUE", {
                id: connectorId,
                connector_status_number: 1
              });
            } else if (
              newList.every(({device_status}) => device_status.number == 2)
            ) {
              this.commit("dashboard/SET_CONNECTOR_VALUE", {
                id: connectorId,
                connector_status_number: 2
              });
            }
          }
        } else {
          let connId;
          list.forEach((item) => {
            // finds the connector id from which the resource belongs
            // let accessProperty = null;
            // for (let key of Object.keys(RESOURCE_STATE_MAP).reverse()) {
            //   if (accessProperty) {
            //     accessProperty = accessProperty[key];
            //   }
            //   if (key == resource) {
            //     accessProperty = item;
            //   }
            // }
            // let connId = accessProperty.id;
            if (resource == "connector") {
              connId = item.id;
            } else if (resource == "device") {
              connId = item?.connector_id;
            } else if (resource == "data") {
              connId = item?.device?.connector?.id;
            } else if (resource == "alarm") {
              connId = item?.data?.device?.connector?.id ?? item?.connector_id;
            } else {
              connId = null;
            }
            if (!connId) return;
            // create an empty object for given connector if it doesn't exist yet
            if (!state.connectorsResources[connId]) {
              Vue.set(state.connectorsResources, connId, {});
            }
            let currentList =
              state.connectorsResources[connId][resource + "List"] ?? [];
            // check if duplicated
            let index = currentList.findIndex(({id}) => id == item.id);
            if (index != -1) {
              // if forceUpdate is true, the resource will be updated
              if (forceUpdate) {
                currentList[index] = item;
                Vue.set(
                  state.connectorsResources[connId],
                  resource + "List",
                  currentList
                );
              } else {
                // it restores previously stored data value that might be overwritten by the editor input simulation
                if (
                  resource == "data" &&
                  !isEqual(
                    currentList[index]?.current_value,
                    item?.current_value
                  )
                ) {
                  currentList[index].current_value = item.current_value;
                  Vue.set(
                    state.connectorsResources[connId],
                    resource + "List",
                    currentList
                  );
                }
                return;
              }
            } else {
              Vue.set(
                state.connectorsResources[connId],
                resource + "List",
                currentList.concat(item)
              );
            }
          });
        }
      } else {
        // if it's a connector
        var currentList = state[RESOURCE_STATE_MAP[resource]] ?? [];
        var newList = currentList;
        // if forceUpdate is true, resources
        // already in the list will be updated
        if (forceUpdate) {
          newList = currentList.map(
            (item) => list.find(({id}) => id == item.id) || item
          );
        }
        // filter duplicated
        list = list.filter(
          (item) => !currentList.some(({id}) => id == item.id)
        );
        newList = newList.concat(list);
        // }
        Vue.set(state, RESOURCE_STATE_MAP[resource], newList);
      }
      state.listUpdateCounter += 1;
    },
    SET_DASHBOARD_EQUIPMENT_ID(state, connectorId) {
      state.dashboardEquipmentId = connectorId;
    },
    SET_DASHBOARD_SCREEN_ID(state, connectorId) {
      state.dashboardScreenId = connectorId;
    },
    CREATE_SESSION(state) {
      this.commit("dashboard/RESET_SESSION");
      state.sessionId = Math.round(Math.random() * 1000);
    },
    RESET_SESSION(state) {
      state.tasks = (state.tasks || []).filter(({id}) => id != state.sessionId);
      state.sessionId = null;
      Object.keys(state.dirtyForms).forEach((key) => {
        Vue.delete(state.dirtyForms, key);
      });
    },
    SET_DATA_VALUE(state, value) {
      let lst = value instanceof Array ? value : [value];
      let connector = null,
        ix = -1;
      lst.forEach((entry) => {
        let dataId = entry ? entry?.data_id || entry?.id || "" : "";
        if (dataId) {
          // full data record
          if (entry.etag && entry.device && entry.device.connector) {
            connector = (state.connectorList || []).find(
              ({id}) => parseInt(entry.device.connector.id) == parseInt(id)
            );
            if (!connector) return;
            dataAdapter({result: [entry]});
            ix = (
              state.connectorsResources[connector.id]?.dataList || []
            ).findIndex(({id}) => parseInt(id) == parseInt(dataId));
            if (ix == -1) {
              // it is a new data add it
              state.connectorsResources[connector.id]?.dataList.unshift(entry);
            } else {
              Vue.set(
                state.connectorsResources[connector.id].dataList,
                ix,
                entry
              );
            }
            state.listUpdateCounter += 1;
            return;
          }
          // parcial data record
          for (var connectorId in state.connectorsResources || {}) {
            let connector = (state.connectorList || []).find(
              ({id}) => parseInt(connectorId) == parseInt(id)
            );
            let data = (
              state.connectorsResources[connectorId]?.dataList || []
            ).find((item) => item.id == dataId);
            if (data) {
              if ("value" in entry) {
                data.current_value = {
                  id: dataId,
                  value: entry.value ?? "",
                  date_time: entry.date_time || new Date().toISOString()
                };
                currentValueTypeCast(data);
                Vue.set(data, "current_value", data.current_value);
              } else if (entry?.current_value) {
                Vue.set(data, "current_value", entry.current_value);
              }
              if (
                data?.current_value?.date_time &&
                connector &&
                (!connector.last_activity_at ||
                  new Date(data.current_value.date_time).getTime() >
                    new Date(connector.last_activity_at).getTime())
              ) {
                Vue.set(
                  connector,
                  "last_activity_at",
                  data.current_value.date_time
                );
              }
              if ("enabled" in entry) {
                Vue.set(data, "enabled", entry.enabled);
              }
              if ("has_active_alarms" in entry) {
                Vue.set(data, "has_active_alarms", entry.has_active_alarms);
              }
              if ("pending_commands" in entry) {
                Vue.set(data, "pending_commands", entry.pending_commands);
                Vue.set(
                  data,
                  "pending_command",
                  entry.pending_commands.length > 0
                );
              }
              if ("pending_mapping_write" in entry) {
                Vue.set(
                  data,
                  "pending_mapping_write",
                  entry.pending_mapping_write
                );
              }
              if ("restore" in entry) {
                if (
                  !data.restore ||
                  data?.restore?.value != entry?.restore?.value
                ) {
                  Vue.set(data, "restore", {
                    ...(data?.restore ?? {}),
                    ...entry.restore
                  });
                }
              }
              state.listUpdateCounter += 1;
              return;
            }
          }
        }
      });
      state.dataValueSync += 1;
    },
    SET_ALARM_VALUE(state, value) {
      let lst = value instanceof Array ? value : [value];
      let cfg = (Vue.http.options && Vue.http.options.config) || {};
      let item;
      lst.forEach((entry) => {
        let id = entry ? entry?.alarm_id || entry?.id || "" : "";
        if (!id) return;
        for (var connectorId in state.connectorsResources || {}) {
          item = (state.connectorsResources[connectorId]?.alarmList || []).find(
            (i) => parseInt(i.id) === parseInt(id)
          );
          if (item) {
            for (var p in entry) {
              if (item[p] !== undefined) {
                item[p] = entry[p];
              }
            }
            if (
              item.data_id &&
              "data_value" in entry &&
              entry.data_value !== "" &&
              entry.data_value !== null
            ) {
              let data = (
                state.connectorsResources[connectorId]?.dataList || []
              ).find((data) => item.data_id == data.id);
              if (data) {
                currentValueTypeCast(data);
                var current_value = data.current_value || {};
                var vlr = parseFloat(entry.data_value);
                current_value.value =
                  data.type == "string" || isNaN(vlr) ? entry.data_value : vlr;
                Vue.set(data, "current_value", current_value);
              }
            } else if (entry.data) {
              Vue.set(item, "data", entry.data);
            }
            if ("is_active" in entry && entry.is_active !== "") {
              Vue.set(item.alarm_current_state, "state", entry.is_active);
            }
            if ("alarm_state_id" in entry && entry.alarm_state_id !== "") {
              var alarm_state = cfg.references.alarm_states.find(
                (state) => state.id == entry.alarm_state_id
              );
              if (alarm_state) {
                alarm_state = JSON.parse(JSON.stringify(alarm_state));
                delete alarm_state.description;
                Vue.set(item.alarm_current_state, "alarm_state", alarm_state);
                if (alarm_state.id == 3) {
                  // ack
                  Vue.set(
                    item.alarm_current_state,
                    "acknowledgment_state",
                    true
                  );
                }
              }
            }
            if (
              "last_transition_at" in entry &&
              entry.last_transition_at !== ""
            ) {
              Vue.set(
                item.alarm_current_state,
                "datetime_last_transition",
                entry.last_transition_at
              );
            }
            // console.log(item);
            state.listUpdateCounter += 1;
            return;
          }
        }
      });
    },
    SET_CONNECTOR_VALUE(state, value) {
      let lst = value instanceof Array ? value : [value];
      const propMap = {
        is_connected: "is_connected", // regular connector entity
        connected: "is_connected", // mqtt | state entity version
        has_active_alarms: "has_active_alarms",
        enabled: "enabled",
        connector_status_number: "connector_status"
      };
      const cfg = (Vue.http.options && Vue.http.options.config) || {};
      const cfgStatus = cfg?.references?.connector_status ?? [];

      lst.forEach((entry) => {
        let id = entry ? entry?.connector_id || entry?.id || "" : "";
        if (id) {
          let ix = (state.connectorList || []).findIndex(
            (item) => parseInt(item.id) == parseInt(id)
          );
          if (ix >= 0) {
            let connector = state.connectorList[ix];
            let connState = null;
            for (var p in entry) {
              if (p in propMap && entry[p] !== "") {
                if (p == "connector_status_number") {
                  // todo: replace complete state object from cfg
                  connState =
                    cfgStatus.find(
                      ({number}) => parseInt(number) == parseInt(entry[p])
                    ) || null;
                  if (connState) {
                    connector.connector_status = {...connState};
                    connector.is_connected =
                      connector.connector_status.number == 1;
                  }
                } else {
                  connector[propMap[p]] = entry[p];
                }
              } else if (p in connector) {
                if (typeof entry[p] == "object") {
                  Vue.set(connector, p, entry[p]);
                } else {
                  connector[p] = entry[p];
                }
              }
            }
            // The piece of code below should not be necessary, but it seems connector object does not always
            // share the same instance among its device list (maz - review it)
            (state.connectorsResources[id]?.dataList || []).forEach((data) => {
              if (data?.device?.connector) {
                for (var p in propMap) {
                  if (p in entry && entry[p] !== "") {
                    if (p == "connector_status_number") {
                      data.device.connector.connector_status = {...connState};
                    } else {
                      Vue.set(data?.device?.connector, propMap[p], entry[p]);
                    }
                  }
                }
                if (!connector.is_connected) {
                  // device state must be also updated
                  Vue.set(data.device, "is_connected", false);
                }
              }
            });
            if (connState) {
              // TODO: update here should be only if there is a single device.
              // But once that all status update will be triggered by device events, instead of connector. it is wise to keep this way
              (state.connectorsResources[id]?.deviceList || []).forEach(
                (device) => {
                  Vue.set(device, "device_status", {...connState});
                  Vue.set(device, "is_connected", connector.is_connected);
                  if (
                    device?.connector?.connector_status &&
                    device.connector.connector_status.number != connState.number
                  ) {
                    Vue.set(device.connector, "connector_status", connState);
                  }
                }
              );
            }
            Vue.set(state.connectorList, ix, connector);
          } else if ("base_model" in entry && entry.id) {
            // it is a connector and not listed, then add it
            state.connectorList = state.connectorList || [];
            state.connectorList.unshift(entry);
          }
        }
      });
      state.connectorValueSync += 1;
    },
    SET_DEVICE_VALUE(state, value) {
      let lst = value instanceof Array ? value : [value];
      const propMap = {
        is_connected: "is_connected", // regular connector entity
        connected: "is_connected", // mqtt | state entity version
        has_active_alarms: "has_active_alarms",
        enabled: "enabled",
        device_status_number: "device_status"
      };
      let connectorDeviceList = [],
        deviceId = null,
        device = null,
        pos = null;
      const cfg = (Vue.http.options && Vue.http.options.config) || {};
      const cfgStatus = cfg?.references?.connector_status ?? [];
      lst.forEach((entry) => {
        deviceId = entry ? entry?.device_id || entry?.id || "" : "";
        if (deviceId) {
          for (var connectorId in state.connectorsResources || {}) {
            connectorDeviceList =
              state.connectorsResources[connectorId]?.deviceList || [];
            device = connectorDeviceList.find((item) => item.id == deviceId);
            pos = connectorDeviceList.findIndex(
              (item) => parseInt(item.id) === parseInt(deviceId)
            );
            if (pos >= 0) {
              device = connectorDeviceList[pos];
              let devState = null;
              for (var p in entry) {
                if (p in propMap && entry[p] !== "") {
                  if (p == "device_status_number") {
                    // todo: replace complete state object from cfg
                    devState =
                      cfgStatus.find(
                        ({number}) => parseInt(number) == parseInt(entry[p])
                      ) || null;
                    if (devState) {
                      device.device_status = {...devState};
                      device.is_connected = device.device_status.number == 1;
                      // TODO: should not be necessary after device mqtt update
                      // sync connector state back
                      let ix = (state.connectorList || []).findIndex(
                        ({id}) => parseInt(id) == parseInt(connectorId)
                      );
                      if (ix >= 0) {
                        let connector = state.connectorList[ix];
                        if (connector && connector.connector_status) {
                          if (devState.number == 1 || devState.number == 2) {
                            Vue.set(connector, "connector_status", {
                              ...devState
                            });
                          }
                        }
                      }
                    }
                  } else {
                    device[propMap[p]] = entry[p];
                  }
                } else if (p in device) {
                  device[p] = entry[p];
                }
              }
              Vue.set(connectorDeviceList, pos, device);
              return;
            }
          }
        }
      });
      state.connectorValueSync += 1;
    },
    REMOVE_CONNECTOR(state, value) {
      let lst = value instanceof Array ? value : [value];
      if (!lst.length) return;
      lst.forEach((cid) => {
        let ix = (state.connectorList || []).findIndex(({id}) => id == cid);
        if (ix >= 0) {
          Vue.delete(state.connectorList, ix);
          Vue.delete(state.connectorsResources, cid);
        }
      });
    },
    REMOVE_RESOURCES(state, list) {
      if (!list.length) return;
      let resources = state.connectorsResources || {};
      list.forEach((o) => {
        if (o.connector_id) {
          this.commit("dashboard/REMOVE_CONNECTOR", o.connector_id);
        } else {
          let e = o.device_id
            ? ["deviceList", o.device_id]
            : o.data_id
            ? ["dataList", o.data_id]
            : o.alarm_id
            ? ["alarmList", o.alarm_id]
            : null;
          if (!e) return;
          for (var cid in resources) {
            let c = (resources[cid][e[0]] && resources[cid][e[0]].length) || 0;
            if (c) {
              resources[cid][e[0]] = (resources[cid][e[0]] || []).filter(
                ({id}) => parseInt(id) !== parseInt(e[1])
              );
              if (resources[cid][e[0]].length < c) {
                if (
                  e[0] == "deviceList" &&
                  (resources[cid]["dataList"] || []).length
                ) {
                  let ret,
                    data_ids = [];
                  resources[cid]["dataList"] = (
                    resources[cid]["dataList"] || []
                  ).filter(({id, device}) => {
                    ret = !device || parseInt(device.id) !== parseInt(e[1]);
                    if (!ret) data_ids.push(id);
                    return ret;
                  });
                  if (data_ids.length) {
                    resources[cid]["alarmList"] = (
                      resources[cid]["alarmList"] || []
                    ).filter(({data, data_id}) => {
                      return !(
                        (data && data_ids.indexOf(data.id) >= 0) ||
                        (data_id && data_ids.indexOf(data_id) >= 0)
                      );
                    });
                  }
                } else if (
                  e[0] == "dataList" &&
                  (resources[cid]["alarmList"] || []).length
                ) {
                  resources[cid]["alarmList"] = (
                    resources[cid]["alarmList"] || []
                  ).filter(({data, data_id}) => {
                    return (
                      (data && parseInt(data.id) !== parseInt(e[1])) ||
                      (data_id && parseInt(data_id) !== parseInt(e[1]))
                    );
                  });
                }
                break;
              }
            }
          }
        }
      });
      Vue.set(state, "connectorsResources", resources);
    },
    SET_CLIPBOARD(state, entry) {
      state.clipboard = entry;
    },
    SET_APPLICABLE_STYLE(state, entry) {
      Vue.set(
        state,
        "applicableStyle",
        entry ? JSON.parse(JSON.stringify(entry)) : null
      );
    },
    // value {connector_id: , properties:{name:,value:}}
    SET_CONNECTOR_PROPERTY_VALUE(state, value) {
      let lst = value instanceof Array ? value : [value];
      lst.forEach((entry) => {
        let id = entry ? entry?.connector_id || entry?.id || "" : "";
        if (id) {
          let connector = (state.connectorList || []).find(
            (item) => item.id == id
          );
          if (connector && typeof connector == "object") {
            for (var p in entry?.properties || {}) {
              Vue.set(connector, p, entry.properties[p]);
              var prop = (connector.portal_data.extended_properties || []).find(
                ({name}) => name == p
              );
              if (prop) {
                Vue.set(prop, "value", entry.properties[p]);
                if (
                  p in (connector?.user_data?.extended_properties || {}) &&
                  connector?.user_data?.extended_properties[p] !=
                    entry.properties[p]
                ) {
                  if (p in (connector?.user_data?.extended_properties || {})) {
                    Vue.set(
                      connector.user_data.extended_properties,
                      p,
                      entry.properties[p]
                    );
                  }
                }
              }
            }
          }
        }
      });
    },
    SET_MANUAL_REFRESH(state, value) {
      state.manualRefresh = value;
    },
    UPDATE_SETTINGS_COUNTER(state) {
      state.settingUpdateCounter += 1;
    },
    SET_CONTROL_DATA_SELECTOR_SOURCE(state, value) {
      state.controlDataSelectorSource = value || null;
    },
    SORT_PANELS(state) {
      if (!state?.draft?.template?.panels?.length) return;
      const pos = (t, n) => {
        let p = panelPosition(t, n);
        p.row = `${p.row}`.padStart(3, 0);
        p.col = `${p.col}`.padStart(3, 0);
        return `${p.row},${p.col}`;
      };
      state.draft.template.panels = (state.draft.template.panels || [])
        .map((p) => ({
          ix: pos(state.draft.template, p.name),
          p: p
        }))
        .sort((a, b) => (a.ix > b.ix ? 1 : a.ix < b.ix ? -1 : 0))
        .map(({p}) => p);
    },
    SET_DATA_DISPLAY_LABEL(state, value) {
      state.dataDisplayLabel = value;
      let entry = editorSettings();
      entry.dataDisplayLabel = value;
      editorSettings(entry);
    },
    SET_SHOW_DELETED_SCREENS(state, value) {
      state.showDeletedScreens = value;
      //Vue.set(state, "showDeletedScreens", value);
      let entry = editorSettings();
      entry.showDeletedScreens = state.showDeletedScreens;
      editorSettings(entry);
    },
    DRAGGING(state, value) {
      state.dragging = value;
    },
    SIMULATION(state, value) {
      state.simulation = value;
    },
    SET_DIRTY_FORM(state, {form, dirty}) {
      Vue.set(state.dirtyForms, form, dirty);
    }
  },
  actions: {
    init(context) {
      context.commit("IS_LOADING", true);
      context.commit("IS_READY", true);
      let ctx = context;
      window.addEventListener(
        "storage",
        (e) => {
          if (ctx && ctx?.state?.draft) {
            if (!_draft(ctx.state.draft.screenId)) {
              // Current draft was removed on another browser tab (maybe after publish it)
              // Reset current draft dashboard at memory level only and as soon it get selected again (different tab)
              // an out of date message message displayed
              ctx.commit("RESET_DRAFT");
            }
          }
        },
        true
      );
    },
    reset(context) {
      context.commit("RESET");
      context.commit("IS_LOADING", false);
      context.commit("IS_READY", true);
    },
    initEditor(context, entry) {
      if (!entry) return;
      if (context.state.mode != "editor") context.commit("SET_MODE", "editor");
      if (entry.template) {
        context.commit("SAVE_DRAFT", {
          screenId: entry.screenId,
          template: entry.template
        });
      }
      if (entry.panelName) {
        context.commit("SET_EDITOR_PANEL", entry);
      }
    },
    setMode(context, mode) {
      context.commit("SET_MODE", mode);
    },
    create(context, template) {
      return new Promise((resolve) => {
        let entry = {
          screenId: null,
          template: template || null
        };
        context.commit("CREATE", entry);
        context.commit("SAVE_DRAFT", entry);
        resolve(context.state.draft);
      });
    },
    remove(context, screenId) {
      context.dispatch("removeDraft", screenId);
      context.commit("DEL_SCREEN", screenId);
    },
    async fetchTemplate(context, options) {
      let id;
      let updateStatus = true;
      if (typeof options == "object") {
        id = options.screenId;
        updateStatus = options.updateStatus;
      } else {
        id = options;
      }
      if (updateStatus) {
        context.commit("IS_READY", false);
      }
      if (id) {
        let screen = (context.getters["screens"] || []).find((i) => i.id == id);
        let path = screen?.path || "";
        if (path) {
          try {
            let template = await _dashboardService.getTemplate(
              path,
              context.getters.screens
            );
            context.commit("SET_TEMPLATE", {id: id, data: template});
            return template;
          } catch (e) {
            //console.error(e)
            //throw e;
          } finally {
            if (updateStatus) {
              context.commit("IS_READY", true);
            }
          }
        }
      }
      Vue.nextTick(() => {
        context.commit("IS_READY", true);
      });
    },
    setDashboardEquipmentId(context, id) {
      context.commit("SET_DASHBOARD_EQUIPMENT_ID", id);
    },
    fetchConnector(context, query) {
      if (!query || !query.id) return;
      context
        .dispatch("fetchDevices", {
          connector_id: query.id
        })
        .then((ret) => {
          if (!ret || !query.deep) return;
          context.dispatch("fetchResourcesFrom", {
            connectorId: query.id,
            resource: "data",
            forceUpdate: true
          });
          context.dispatch("fetchResourcesFrom", {
            connectorId: query.id,
            resource: "alarm",
            forceUpdate: true
          });
        });
    },
    fetchDevices(context, query) {
      return new Promise((resolve) => {
        var _query = {
          ...(query || {}),
          contract_id: context.rootGetters["user/contract_id"],
          resource: "device",
          filters: {}
        };
        let srv = new RESOURCE_SERVICE_MAP["device"]();
        srv.fetch(_query).then((devices) => {
          if (devices.length) {
            deviceListAdapter(devices);
            let connService = new ConnectorService();
            let equipService = new EquipmentService();
            let connectors = {};
            let connector;
            let firstTime = true;
            devices.forEach((device) => {
              if (!connectors[device.connector.id]) {
                connector = equipService.equipmentAdapter(
                  device.connector,
                  connService
                );
                let extendedProperties = equipService.getExtendedProperties(
                  connector
                );
                Object.assign(connector, extendedProperties);
                if (
                  (context.state.connectorList || []).find(
                    ({id}) => parseInt(id) === parseInt(device.connector.id)
                  )
                ) {
                  firstTime = false;
                  context.commit("SET_CONNECTOR_VALUE", connector);
                } else {
                  context.commit("ADD_RESOURCE", {
                    resource: "connector",
                    list: [connector]
                  });
                }
                connectors[connector.id] = [];
              }
              connectors[device.connector.id].push(device);
              // delete device.connector;
            });
            for (var connectorId in connectors) {
              // if (firstTime) {
              //   context.commit("ADD_RESOURCE", {
              //     resource: "device",
              //     list: connectors[connectorId],
              //     connectorId: connectorId
              //   });
              // } else {
              //   context.commit("SET_DEVICE_VALUE", connectors[connectorId]);
              //   context.commit("SET_DEVICE_VALUE", connectors[connectorId]);
              // }
              context.commit("ADD_RESOURCE", {
                resource: "device",
                list: connectors[connectorId],
                connectorId: connectorId,
                forceUpdate: !firstTime
              });
            }
          }
          resolve(devices);
        });
      });
    },
    fetchDataSamples(context, query) {
      return new Promise((resolve) => {
        let service = new RESOURCE_SERVICE_MAP["data"]();
        if (query) {
          return service.fetchSamples(query, "current").then((result) => {
            if (typeof result == "object" && result.length) {
              context.dispatch(
                "setDataValue",
                result.map((i) => ({...i, restore: {...i}}))
              );
            }
            resolve(result);
          });
        } else {
          let data_ids = [];
          let fetchs = [];
          let connIds = {};
          (context.getters["dataList"] || []).forEach(({clp_id, id}) => {
            connIds[clp_id] = connIds[clp_id] || [];
            connIds[clp_id].push(id);
          });
          Object.keys(connIds).forEach((connId) => {
            if (context.getters["hasResourceFrom"]("data", connId)) {
              fetchs.push(
                service.fetchSamples(
                  {
                    contract_id: context.rootGetters["user/contract_id"],
                    connector_id: connId
                  },
                  "current"
                )
              );
            } else {
              data_ids = data_ids.concat(connIds[connId]);
            }
          });
          if (data_ids.length) {
            fetchs.push(
              service.fetchSamples(
                {
                  contract_id: context.rootGetters["user/contract_id"],
                  data_ids: data_ids.join(",")
                },
                "current"
              )
            );
          }
          Promise.all(fetchs).then((result) => {
            result.forEach((samples) => {
              if (typeof samples == "object" && samples.length) {
                context.dispatch(
                  "setDataValue",
                  samples.map((i) => ({...i, restore: {...i}}))
                );
              }
            });
            resolve(result);
          });
        }
      });
    },
    fetchDataAlarmsState(context, dataIds) {
      let contractId = context.rootGetters["user/contract_id"];
      const query = {
        contract_id: contractId,
        data_ids: dataIds.join(","),
        only_state: true
      };
      let srv = new RESOURCE_SERVICE_MAP["alarm"]();
      srv.fetch(query).then((result) => {
        if (typeof result == "object" && result?.length) {
          context.commit("SET_ALARM_VALUE", result);
        }
      });
    },
    fetchDataState(context, query) {
      return new Promise((resolve) => {
        const srv = new RESOURCE_SERVICE_MAP["data"]();
        let qry = {
          ...(query || {}),
          ...{
            contract_id: context.rootGetters["user/contract_id"],
            only_state: true,
            _: new Date().getTime()
          }
        };
        srv.fetch(qry).then((result) => {
          if (typeof result == "object" && result?.length) {
            context.commit("SET_DATA_VALUE", result);
          }
          resolve(result);
        });
      });
    },
    fetchDevicesState(context, payload) {
      const contractId = context.rootGetters["user/contract_id"];
      const connIds = (typeof payload != "object"
        ? []
        : payload?.length
        ? payload
        : payload?.connectorIdList ?? []
      ).filter((id) => !isNaN(Number(id)));
      let query = {
        contract_id: contractId,
        only_state: true
      };
      if (connIds.length) {
        query.connector_id = connIds.join(",");
      }
      const srv = new RESOURCE_SERVICE_MAP["device"]();
      srv.fetch(query).then((result) => {
        if (result && result.length) {
          context.dispatch("setDeviceValue", result);
        }
      });
    },
    fetchResourcesState(context, payload) {
      return new Promise((resolve) => {
        const contractId = context.rootGetters["user/contract_id"];
        const skipConnectionStatus = payload?.skipConnectionStatus
          ? true
          : false;
        let connIds = (typeof payload != "object"
          ? []
          : payload?.length
          ? payload
          : payload?.connectorIdList ?? []
        ).filter((id) => !isNaN(Number(id)));

        let query = null;
        let srv = null;
        let fetchs = [];
        let setters = [];
        //==============================
        // CONNECTOR by ConnectorId
        // promise index: 0
        if (skipConnectionStatus) {
          // Since the connector state is going to be updated by a mqtt message, so device then.
          if (
            !connIds.length &&
            context.rootGetters.brokerStatus == "CONNECTED"
          ) {
            connIds = (context.getters["connectorList"] || [])
              .filter(({is_connected}) => is_connected)
              .map(({id}) => id);
          }
        } else {
          srv = new RESOURCE_SERVICE_MAP["connector"]();
          query = {
            contract_id: contractId,
            only_state: true
          };
          if (connIds.length) {
            query.ids = connIds.join(",");
          }
          setters.push("setConnectorValue");
          fetchs.push(srv.fetch(query));
        }

        //==============================
        // DEVICE by ConnectorId
        // promise index: 1
        srv = new RESOURCE_SERVICE_MAP["device"]();
        query = {
          contract_id: contractId,
          only_state: true
        };
        if (connIds.length) {
          query.connector_id = connIds.join(",");
        }
        setters.push("setDeviceValue");
        fetchs.push(srv.fetch(query));
        //==============================
        // by design, connector filters are required for data and alarms state refresh
        if (connIds.length) {
          //==============================
          // DATA by ConnectorId
          // promise index: 2
          srv = new RESOURCE_SERVICE_MAP["data"]();
          let data_ids = [];
          let qryByConn = {}; // query by connector
          (context.getters["dataList"] || [])
            .filter(({clp_id}) =>
              connIds.some((i) => parseInt(i) == parseInt(clp_id))
            )
            .forEach(({clp_id, id}) => {
              qryByConn[clp_id] = qryByConn[clp_id] || [];
              qryByConn[clp_id].push(id);
            });
          Object.keys(qryByConn).forEach((connId) => {
            if (context.getters["hasResourceFrom"]("data", connId)) {
              query = {
                contract_id: contractId,
                connector_id: connId,
                only_state: true
              };
              setters.push("setDataValue");
              fetchs.push(srv.fetch(query));
            } else {
              data_ids = data_ids.concat(qryByConn[connId]);
            }
          });
          //==============================
          // DATA by DataId
          // promise index: 3
          if (data_ids.length) {
            query = {
              contract_id: contractId,
              ids: data_ids.join(","),
              only_state: true
            };
            setters.push("setDataValue");
            fetchs.push(srv.fetch(query));
          }
          //==============================
          // ALARM by ConnectorId
          // promise index: 4
          srv = new RESOURCE_SERVICE_MAP["alarm"]();
          Object.keys(qryByConn).forEach((connId) => {
            // if (context.getters["hasResourceFrom"]("alarm", connId)) {
            query = {
              contract_id: contractId,
              connector_id: connId,
              only_state: true
            };
            setters.push("setAlarmValue");
            fetchs.push(srv.fetch(query));
            // }
          });
        } else {
          if ((context.getters["alarmList"] || []).length) {
            srv = new RESOURCE_SERVICE_MAP["alarm"]();
            query = {
              contract_id: contractId,
              only_state: true
            };
            setters.push("setAlarmValue");
            fetchs.push(srv.fetch(query));
          }
        }
        // perform them all
        Promise.all(fetchs).then((result) => {
          //b24task: https://hitecnologia.bitrix24.com.br/workgroups/group/47/tasks/task/view/24635/
          for (var ix in setters) {
            if (typeof result[ix] == "object" && result[ix]?.length) {
              context.dispatch(setters[ix], result[ix]);
            }
          }
          resolve();
        });
      });
    },
    fetchScreens(context, query) {
      let srv = new ScreenService();
      return srv.fetch(query).then((result) => {
        context.commit("SET_SCREENS", result || []);
        // before adding the unpublished, validates if there postgres items
        // keep in mind:
        //  id<0                 are unpublished template files (local storage only
        //  id>=1000000000       are static template files (file system)
        //  id>0&&id<1000000000  are published templates
        //
        let hasStoredItens = (result || []).some(
          ({id}) => id > 0 && id <= 999999999
        );
        // restore unpublished drafts
        getUnpublishedList().forEach((value) => {
          context.commit("CREATE", {screenId: -1 * value});
        });
        // clean up - remove old draft files from browser storage
        if (hasStoredItens) {
          let ids = (context.getters["screens"] || [])
            .map(({id}) => id)
            .sort((a, b) => parseInt(a) - parseInt(b));
          let keys = IndexTableDrafts()
            .filter(
              (item) =>
                !item.isNew && ids.indexOf(parseInt(item.screenId)) == -1
            )
            .map(({key}) => key);
          if (keys.length) {
            removeFromLocalStorage(keys);
          }
        }
      });
    },
    fetchScreen({commit}, id) {
      if (!id || /\D/g.test(id)) return;
      let service = new ScreenService();
      return service.get(id).then((result) => {
        if (result && typeof result == "object") {
          commit("SET_SCREEN", result);
        }
      });
    },
    /**
     * Fetches resources of specified type from given connector (or the list of connectors itself).
     * @param {Object} options Options used to fetch resources.
     * @param {string} options.resource
     * Resource type to be fetched. Either "connector", "device", "data" or "alarm".
     * @param {number} [options.connectorId=null]
     * Connector id from which to fetch the resources or "null" if the resources are connectors itself.
     * @param {...Object} options.filters Any other filters to be added to the request.
     */
    async fetchResourcesFrom(
      {commit, dispatch, rootGetters},
      {
        resource,
        connectorId = null,
        forceUpdate = false,
        once = false,
        ...filters
      }
    ) {
      if (resource in RESOURCE_SERVICE_MAP) {
        // let deviceId = rootGetters["deviceId"] || 0;
        let query = {
          contract_id: rootGetters["user/contract_id"],
          ...filters
        };
        let options = arguments[1],
          context = arguments[0];
        if (resource != "connector" && !connectorId) {
          if (!filters) return;
        } else if (resource != "connector") {
          // if (deviceId) {
          //   query.device_id = deviceId;
          // } else {
          //   query.connector_id = connectorId;
          // }
          // if device_id was provided, does not need it should be enough
          if (!query.device_id) {
            query.connector_id = connectorId;
          }
        }
        if (!forceUpdate) {
          let matchingTask = await dispatch("checkExisting", {
            resource,
            connectorId,
            filters
          });
          if (matchingTask) return matchingTask.promise;
        }
        let service = new RESOURCE_SERVICE_MAP[resource]();
        let promise = service.fetch(query);
        promise.then((result) => {
          if (result instanceof Array) {
            if (RESOURCE_ROUTINE_MAP[resource]) {
              ({result} = pipe(...RESOURCE_ROUTINE_MAP[resource])({
                result,
                options,
                context
              }));
            }
            commit("ADD_RESOURCE", {
              resource,
              list: result,
              connectorId,
              forceUpdate,
              hasFilters: Object.keys(filters).length > 0
            });
            let task = {resource, query: connectorId};
            if (Object.keys(filters).length) task.filters = filters;
            commit("FINISH_TASK", task);
          }
          return result;
        });
        let task = {resource, query: connectorId, promise};
        if (Object.keys(filters).length) task.filters = filters;
        if (once) task.once = true;
        commit("ADD_TASK", task);
        return promise;
      }
    },
    /**
     * Fetches a list of resources by their ids
     * @param {Object} options Options to perform the operation
     * @param {number[]} list List of resource ids
     */
    async fetchResources(
      {commit, dispatch, rootGetters},
      {resource, list, forceUpdate = false, once = false}
    ) {
      if (resource in RESOURCE_SERVICE_MAP) {
        let options = arguments[1],
          context = arguments[0];
        let promises;
        if (!forceUpdate) {
          ({list, promises} = await dispatch("checkExisting", {
            resource,
            list
          }));
        }
        if (!list) return Promise.all(promises);
        let service = new RESOURCE_SERVICE_MAP[resource]();
        // let promise = Promise.all(
        //   list.map((id) => service.get(id, rootGetters["user/contract_id"]))
        // );
        let promise = null;
        if (resource == "data") {
          promise = service.fetch({
            contract_id: rootGetters["user/contract_id"],
            ids: list.join(",")
          });
        } else {
          promise = Promise.all(
            list.map((id) => service.get(id, rootGetters["user/contract_id"]))
          );
        }

        promise.then((result) => {
          let validResult = result.filter(
            (response) => response && typeof response == "object"
          );
          if (RESOURCE_ROUTINE_MAP[resource]) {
            ({result: validResult} = pipe(...RESOURCE_ROUTINE_MAP[resource])({
              result: validResult,
              options,
              context
            }));
          }
          commit("ADD_RESOURCE", {resource, list: validResult, forceUpdate});
          commit("FINISH_TASK", {resource, query: list});
          return result;
        });
        let task = {resource, query: list, promise};
        if (once) task.once = true;
        commit("ADD_TASK", task);
        return promise;
      }
    },
    async checkExisting(
      {commit, state, rootGetters},
      {resource, list, connectorId, filters}
    ) {
      let tasks = state.tasks;
      if (list) {
        // find tasks related to specified resource
        let matchingTasks = tasks.filter((task) => task.resource == resource);
        let commonTasks = [];
        for (let task of matchingTasks) {
          let commonTask = false;
          // if task query is a list of id
          if (task.query instanceof Array) {
            list = list.filter((id) => {
              let common = task.query.filter((taskDataId) => id == taskDataId);
              if (common.length) commonTask = true;
              return !common.length;
            });
          } else {
            // if query is a connector id
            // await and check results
            let result = await task.promise;
            if (result instanceof Array) {
              list = list.filter(
                (id) => !result.some(({id: resourceId}) => id == resourceId)
              );
            }
          }

          if (commonTask) commonTasks.push(task.promise);
        }
        if (!list.length) {
          return {promises: commonTasks};
        }
        return {list};
      } else {
        // check for task with the same resource and connectorId and equivalent filters
        let matchingTask = tasks.find(
          (task) =>
            task.resource == resource &&
            task.query == connectorId &&
            (isEqual(
              task.filters,
              !Object.keys(filters).length ? undefined : filters
            ) ||
              (!task.filters && Object.keys(filters).length))
        );
        return matchingTask;
      }
    },
    fetchResourceList(
      {dispatch},
      {
        source: {resourceIds = [], referenceIds = [], connectorId},
        forceUpdate = false,
        once = false
      }
    ) {
      let resources = resourceIds.reduce((obj, {id, resource}) => {
        obj[resource] = (obj[resource] || []).concat(id);
        return obj;
      }, {});
      // group reference ids by connector id and resource
      let referenceIdGroups = referenceIds.reduce(
        (obj, {id, connectorId, resource}) => {
          if (connectorId)
            obj[`${connectorId}:${resource}`] = (
              obj[`${connectorId}:${resource}`] ?? []
            ).concat(id);
          return obj;
        },
        {}
      );
      referenceIdGroups = Object.keys(referenceIdGroups).map((key) => ({
        connectorId: key.split(":")[0],
        resource: key.split(":")[1],
        reference_ids: referenceIdGroups[key].join(",")
      }));
      return Promise.all(
        [
          ...Object.keys(resources).map((resource) =>
            dispatch(
              "dashboard/fetchResources",
              {resource, list: resources[resource], forceUpdate, once},
              {root: true}
            )
          ),
          ...referenceIdGroups
            .map(({reference_ids, connectorId, resource}) =>
              dispatch(
                "dashboard/fetchResourcesFrom",
                {
                  resource,
                  connectorId,
                  forceUpdate,
                  reference_ids,
                  once
                },
                {root: true}
              )
            )
            .filter((p) => p)
        ].concat(
          connectorId
            ? dispatch(
                "dashboard/fetchResourcesFrom",
                {
                  resource: "data",
                  connectorId,
                  forceUpdate,
                  once
                },
                {root: true}
              )
            : []
        )
      );
    },
    updateRefMap(context, payload) {
      let draft = context.state.draft || null;
      if (!draft?.template) return;
      if (payload.screenId == context.state.draft.screenId) {
        let template = JSON.parse(JSON.stringify(draft.template));
        template.draft = template.draft || {};
        template.draft.refMap = payload.ref_map || null;
        context.commit("SAVE_DRAFT", {
          screenId: context.state.draft.screenId,
          template: template
        });
        context.commit(
          "SET_CONTROL_DATA_SELECTOR_SOURCE",
          template?.draft?.refMap?.conn1 || null
        );
      }
    },
    updateLayout(context, payload) {
      let draft = context.state.draft || null;
      if (!draft?.template) return;

      if (payload.screenId == context.state.draft.screenId) {
        var template = JSON.parse(
          JSON.stringify(context.state.draft.template || null)
        );

        let prevPanels = null;
        (template?.layout || []).forEach((row) => {
          (row || []).forEach((column) => {
            (column?.panels || []).forEach((panelName) => {
              if (!prevPanels) prevPanels = {};
              if (!(panelName in prevPanels)) prevPanels[panelName] = true;
            });
          });
        });

        template.layout = JSON.parse(JSON.stringify(payload.layout || []));

        // updates panels collection
        (template.layout || []).forEach((row) => {
          (row || []).forEach((column) => {
            (column?.panels || []).forEach((panelName) => {
              if (panelName) {
                let panel = (template.panels || []).find(
                  (p) => p.name == panelName
                );
                if (!panel) {
                  panel = Panels.find((p) => p.name == panelName); // regular panel
                  if (!panel && payload?.panels?.length) {
                    panel = payload?.panels.find((p) => p.name == panelName); // client has provided the parsed list
                  }
                  if (panel) {
                    panel = JSON.parse(JSON.stringify(panel)); // cloned version
                    let panels = template.panels || [];
                    panels.push(panel.template);
                  }
                }
                delete prevPanels[panelName];
              }
            });
          });
        });
        //
        // it removes remaining but deleted panels and
        // save a copy of them - that would allow us to re-insert it
        //
        if (prevPanels) {
          let removed = null;
          template.panels = template.panels.filter((panel) => {
            if (panel.name in prevPanels) {
              removed = removed || {};
              removed[panel.name] = JSON.parse(JSON.stringify(panel));
              return false;
            } else {
              return true;
            }
          });
          if (removed) {
            template.draft = template.draft || {};
            template.draft.removed = {
              ...(template.draft.removed || {}),
              ...removed
            };
          }
        }

        context.commit("SAVE_DRAFT", {
          screenId: payload.screenId,
          template: template
        });
      }
    },
    updateProcessArea(context, payload) {
      let draft = context.state.draft || null;
      if (!draft?.template) return;
      if (payload.screenId == context.state.draft.screenId) {
        let template = JSON.parse(JSON.stringify(draft.template));
        template.draft = template.draft || {};
        template.draft.processAreaId = payload.processAreaId;
        context.commit("SAVE_DRAFT", {
          screenId: context.state.draft.screenId,
          template: template
        });
      }
    },
    expand(context, panelName) {
      if (context.state.expandedPanel == panelName) {
        context.commit("SET_EXPANDED_PANEL", "");
      } else {
        context.commit("SET_EXPANDED_PANEL", panelName);
      }
    },
    fullscreen(context, panelName) {
      if (context.state.fullscreenPanel == panelName) {
        context.commit("SET_FULLSCREEN_PANEL", "");
      } else {
        context.commit("SET_FULLSCREEN_PANEL", panelName);
      }
    },
    async initDraft(context, screenId) {
      var draft;
      if (context.state.locked) {
        // wait max of 5s to open it
        for (var i = 0; i < 20; i++) {
          await new Promise((r) => setTimeout(r, 250));
          draft = context.getters["draft"];
          if (draft && draft.screenId == screenId) {
            return draft.template;
          }
        }
        return null;
      }
      let template = null;
      try {
        context.state.locked = true;
        let screen = (context.getters.screens || []).find(
          (i) => i.id == screenId
        );
        if (!screen) {
          context.state.locked = false;
          return;
        }
        template = _draft(screenId);
        if (!template) {
          template = await context.dispatch("fetchTemplate", {
            screenId: screenId,
            updateStatus: false
          });
          if (!template) {
            if (screenId in (context?.state?.templates || {})) {
              template = JSON.parse(
                JSON.stringify(context.state.templates[screenId])
              );
            } else {
              template = JSON.parse(JSON.stringify(Screen));
            }
          }
          template.title = screen.name || template.title;
        }
        // initialize extra dashboard draft properties
        template.draft = template.draft || {};
        if (!("processAreaId" in template.draft)) {
          template.draft.processAreaId =
            (screen.process_area && screen.process_area.id) || null;
        }
        if (!("etag" in template.draft)) {
          template.draft.etag = screen.etag || null;
        }
        if (!("tags" in template.draft)) {
          template.draft.tags = screen?.portal_data?.tags || [];
        }
        if (!("contract_id" in template.draft)) {
          template.draft.contractId =
            context.rootGetters["user/contract_id"] || "";
        }
        // update remote panels if needed
        Object.keys(template?.linkedPanels || {}).forEach((panelName) => {
          let linkedScreen = template?.linkedPanels[panelName];
          if (!linkedScreen.syncEnabled) return;
          screen = (context.getters.screens || []).find(
            ({id}) => id == linkedScreen.screenId
          );
          if (screen) {
            // remote template
            let remTemplate = null;
            try {
              let draftTemplate = window.localStorage.getItem(
                `dashboard(${linkedScreen.screenId})`
              );
              if (draftTemplate) {
                // any local draft will be considered valid for update
                remTemplate = JSON.parse(draftTemplate);
              } else if (screen.etag != linkedScreen.etag) {
                // since it is going to fetch s3, it must first validate etag
                remTemplate = _dashboardService.getFileContent(screen.path);
              }
            } catch (error) {
              console.log(error);
            } finally {
              if (remTemplate) {
                let panel = remTemplate.panels.find(
                  ({name}) => name == panelName
                );
                if (panel) {
                  let pos = template.panels.findIndex(
                    ({name}) => name == panelName
                  );
                  if (pos >= 0) {
                    template.panels[pos] = panelMerge(
                      template.panels[pos],
                      panel,
                      linkedScreen
                    );
                    linkedScreen.etag = screen.etag;
                  }
                } else {
                  // remove link
                  delete template?.linkedPanels[panelName];
                }
              }
            }
          }
        });

        if (!("refMap" in template.draft)) {
          template.draft.refMap = (screen?.reference_connectors || []).length
            ? {conn1: screen?.reference_connectors[0].id}
            : null;
        }

        context.commit("SAVE_DRAFT", {
          screenId: screenId,
          template: template
        });
      } catch (error) {
        console.log(error);
      } finally {
        context.state.locked = false;
      }
      return template;
    },
    draftCleanUp(context) {
      const contract_id = context.rootGetters["user/contract_id"] || "";
      const screens = context.getters["screens"] || [];
      if (!contract_id || !screens.length) return;
      const now = new Date().getTime();
      const timeout = 60 * 60 * 24 * 7 * 1000; // 7 days without any update
      Object.keys(window.localStorage).forEach((k) => {
        if (!k.match(/^dashboard\(\d+\)$/)) return;
        try {
          var item = window.localStorage.getItem(k);
          if (item && typeof item == "string") {
            item = JSON.parse(item);
            // only items on the same contract_id are safe to be removed
            if (
              item &&
              item?.draft?.updated_at &&
              (item?.draft?.contractId || "") == contract_id
            ) {
              if (
                now - item.draft.updated_at > timeout ||
                !screens.some(({id}) => id == k.match(/\d+/)[0])
              ) {
                window.localStorage.removeItem(k);
                // console.log(`${k} removed`);
              }
            }
          }
        } catch (e) {
          console.log(`invalid item draft item ${k}`);
        }
      });
    },
    saveDraftPanel(context, entry) {
      context.commit("SAVE_DRAFT_PANEL", entry);
    },
    resetCurrentDraftPanel(context) {
      context.commit("SET_CURRENT_DRAFT_PANEL", null); // only memory
    },
    saveDraft(context, payload) {
      let draft = context.state.draft || null;
      if (!draft?.template) return;
      if (
        payload &&
        payload.screenId &&
        payload.template &&
        payload.screenId == context.state.draft.screenId
      ) {
        context.commit("SAVE_DRAFT", {
          screenId: payload.screenId,
          template: payload.template
        });
      }
    },
    resetDraft(context) {
      if (context.state.draft) {
        // restore template to its initial template
        // context.dispatch("fetchTemplate", context.state.draft.screenId);
        context.commit("RESET_DRAFT");
      }
    },
    async restoreDraft(context, screenId) {
      await context.dispatch("removeDraft", screenId);
      await context.dispatch("fetchScreen", screenId); // import due etag
      context.commit("DEL_TEMPLATE", screenId);
      await context.dispatch("initDraft", screenId);
    },
    // remove all drafts
    async removeDraft(context, screenId) {
      context.commit("SAVE_DRAFT", {screenId: screenId, template: null});
    },
    sortPanels(context) {
      let draft = context.state.draft || null;
      if (!draft?.template) return;
      context.commit("SORT_PANELS");
      context.commit("SAVE_DRAFT", {
        screenId: draft.screenId,
        template: draft.template
      });
    },
    setDataValue(context, entry) {
      context.commit("SET_DATA_VALUE", entry);
    },
    setAlarmValue(context, entry) {
      context.commit("SET_ALARM_VALUE", entry);
    },
    setConnectorValue(context, entry) {
      context.commit("SET_CONNECTOR_VALUE", entry);
      // if (context.getters.mode == 'editor') return;
      let cList = entry instanceof Array ? entry : [entry];
      let dList = [];
      (cList || []).forEach((item) => {
        var devices =
          context.state?.connectorsResources[item.id]?.deviceList || [];
        if (devices.length == 1) {
          context.commit("SET_DEVICE_VALUE", {
            ...entry,
            id: devices[0].id,
            device_status_number: entry.connector_status_number
          });
        } else if (!item.etag) {
          // it only validates mqtt entries (no etag)
          dList.push(item.id);
        }
      });

      // It forces an API request for those connectors with more than one device;
      if (!dList.length) return;
      var isMqttEnabled =
        Vue.http.options.config?.mqtt?.websocket?.host &&
        Vue.http.options.config?.mqtt?.websocket?.port &&
        (Vue.http.options.config?.mqtt?.enabled ?? true);
      if (!isMqttEnabled) return;
      var srv = new RESOURCE_SERVICE_MAP["device"]();
      var query = {
        contract_id: context.rootGetters["user/contract_id"],
        only_state: true,
        connector_id: dList.map((id) => id).join(",")
      };
      srv.fetch(query).then((resp) => {
        context.dispatch("setDeviceValue", resp);
      });
    },
    setDeviceValue(context, entry) {
      context.commit("SET_DEVICE_VALUE", entry);
    },
    setConnectorPropertyValue(context, entry) {
      context.commit("SET_CONNECTOR_PROPERTY_VALUE", entry);
    },
    createNewSession({commit}) {
      // creates a new dashboard session so
      // the tasks have a different identifier
      // commit("SET_SESSION_ID", Math.round(Math.random() * 1000));
      commit("CREATE_SESSION");
    },
    resetSession({commit}) {
      // reset current dashboard session id and its related tasks
      commit("RESET_SESSION");
    },
    // reruns all generic unique tasks from current session
    refresh({getters, dispatch}, resource) {
      // previous
      // ==========
      // return Promise.all(
      //   getters.genericUniqueTasks.map((task) => {
      //     if (
      //       task.id == getters.sessionId &&
      //       (resource ? resource.includes(task.resource) : true)
      //     )
      //       return task.rerun(dispatch);
      //   })
      // );

      // new
      // ==========
      // it filters redundant request data tasks
      let tasks = getters.genericUniqueTasks || [];
      let connector_id_full_data_list = tasks
        .filter((task) => {
          return (
            task.id == getters.sessionId &&
            task.resource == "data" &&
            task.query &&
            typeof task.query !== "object"
          );
        })
        .map(({query}) => query);
      if (connector_id_full_data_list.length) {
        tasks = tasks.filter((task) => {
          if (
            task.id == getters.sessionId &&
            task.resource == "data" &&
            task.query
          ) {
            var queries;
            var foundList;
            if (typeof task.query === "object" && task.query.length) {
              queries = task.query;
              foundList = queries.filter((query) => {
                return (
                  connector_id_full_data_list.indexOf(
                    (getters.dataList.find(({id}) => id == query) || {})?.clp_id
                  ) >= 0
                );
              });
              return foundList.length !== queries.length;
            } else if (task?.filters?.reference_ids) {
              queries = task?.filters?.reference_ids.split(",");
              foundList = queries.filter((query) => {
                return (
                  connector_id_full_data_list.indexOf(
                    (
                      getters.dataList.find(
                        ({reference_id}) => reference_id == query
                      ) || {}
                    )?.clp_id
                  ) >= 0
                );
              });
              return foundList.length !== queries.length;
            }
          }
          return true;
        });
      }
      return Promise.all(
        tasks.map((task) => {
          if (
            task.id == getters.sessionId &&
            (resource ? resource.includes(task.resource) : true)
          )
            return task.rerun(dispatch);
        })
      );
    },
    removeResources(context, ids) {
      if (!(ids || []).length) return;
      context.commit("REMOVE_RESOURCES", ids);
    },
    setApplicableStyle(context, entry) {
      context.commit("SET_APPLICABLE_STYLE", entry);
    },
    setManualRefresh(context, value) {
      context.commit("SET_MANUAL_REFRESH", value);
    },
    togglePanel(context, value) {
      let payload = editorSettings();
      let lst = value instanceof Array ? value : [value];
      lst.forEach((entry) => {
        if (entry.name && (entry.state === true || entry.state === false))
          payload.togglePanelState[entry.name] = entry.state;
      });
      editorSettings(payload);
      context.commit("UPDATE_SETTINGS_COUNTER");
    },
    setControlDataSelectorSource(context, connectorId) {
      context.commit("SET_CONTROL_DATA_SELECTOR_SOURCE", connectorId || null);
    },
    resetEditor(context) {
      context.dispatch("synoptic/resetPanel", null, {root: true});
      context.dispatch("history/reset", null, {root: true});
      context.dispatch("resetDraft");
      context.dispatch("resetCurrentDraftPanel");
      context.dispatch("setControlDataSelectorSource");
      context.commit("SET_DASHBOARD_EQUIPMENT_ID", null);
    },
    setScreen(context, payload) {
      context.commit("SET_SCREEN", payload);
    },
    addResource(context, payload) {
      if (
        !payload ||
        !((payload?.resource ?? "") in RESOURCE_STATE_MAP) ||
        !(payload?.list ?? []).length
      )
        return;
      context.commit("ADD_RESOURCE", payload);
    },
    setParent(context, config) {
      // set parent id to an item
      try {
        if (!config.dbKey || !config.id) return;
        let dbKey = config.dbKey;
        let entry = window.localStorage.getItem(dbKey);
        if (!entry) return;
        let payload = JSON.parse(entry);
        payload.leaves = payload.leaves || {};
        let parentId;
        let oldId = config.oldId;
        if (oldId && payload.leaves[oldId]) {
          parentId = payload.leaves[oldId];
          delete payload.leaves[oldId];
        } else {
          parentId =
            config.parentId || (payload.show ? payload.selectedNode : "root");
        }
        if (parentId) {
          payload.leaves[config.id] = parentId;
          window.localStorage.setItem(dbKey, JSON.stringify(payload));
        }
      } catch (error) {
        console.log("could not set the parent folder");
      }
    },
    setDataDisplayLabel(context, value) {
      context.commit("SET_DATA_DISPLAY_LABEL", value);
    },
    setShowDeletedScreens(context, value) {
      context.commit("SET_SHOW_DELETED_SCREENS", value);
    },
    dragging(context, value) {
      context.commit("DRAGGING", value);
    },
    setDraftLayout(context, payload) {
      let draft = context.state.draft || null;
      if (!draft?.template) return;
      draft.template.layout = payload;
      context.dispatch("saveDraft", draft);
    },
    updateAfterSave(context, {type, resource}) {
      context.commit(
        {
          alarm: "SET_ALARM_VALUE",
          data: "SET_DATA_VALUE",
          device: "SET_DEVICE_VALUE",
          connector: "SET_CONNECTOR_VALUE"
        }[type],
        resource
      );
      let path_ids = document.location.pathname.match(/\d+/g);
      let cid =
        (path_ids && path_ids.length && path_ids[0]) || resource.id || "";
      if (cid) {
        ((
          {
            connector: ["device", "data", "alarm"],
            device: ["data", "alarm"],
            data: ["alarm"]
          }[type] || []
        ).forEach((n) => {
          context.dispatch("fetchResourcesFrom", {
            connectorId: cid,
            resource: n,
            forceUpdate: true,
            _: new Date().getTime()
          });
        }));
      }
    },
    simulation({commit}, visible) {
      commit("SIMULATION", visible);
    },
    setDirtyForms(context, value) {
      Object.keys(value || {}).forEach((key) => {
        context.commit("SET_DIRTY_FORM", {
          form: key,
          dirty: value[key] ? true : false
        });
      });
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    isReady(state) {
      return state.isReady;
    },
    mode(state) {
      return state.mode;
    },
    scriptList(state) {
      return (state.screens || []).filter(
        ({portal_data}) => portal_data && portal_data?.type === "script"
      );
    },
    hasPrivateScreensLoaded(state) {
      return state.screens != null;
    },
    screens(state, getters) {
      // merge database and locallist
      let cfg = (Vue.http.options && Vue.http.options.config) || {};
      // database list
      // const dbScriptIdList = getters.scriptList.map(({ id }) => id);
      const dbScriptIdList = []; // uncomment this line to be able to save/delete it
      let dbList = (state.screens || []).filter(
        ({id}) => dbScriptIdList.indexOf(id) == -1
      );
      // filesystem list
      let localList = (cfg.screens || [])
        .filter((i) => {
          return dbList.findIndex((j) => j.id == i.id) == -1;
        })
        .map((i) => {
          let screen = {
            id: parseInt(i.id),
            name: i.name,
            path: `${Vue.http.options.dashboard}/screens/${i.id}.json`,
            public: true,
            contract_id: "",
            description: "",
            revision_code: "",
            revision_comment: "",
            default: "default" in i && i.default ? true : false
          };
          return screen;
        });
      // merged list
      return dbList
        .concat(localList)
        .sort((a, b) =>
          a.name.toUpperCase().localeCompare(b.name.toUpperCase())
        );
    },
    activeScreens(state, getters) {
      return getters.screens.filter(
        ({deleted_at, portal_data}) =>
          !deleted_at && (!portal_data || !portal_data.excluded_at)
      );
    },
    screen(state, getters) {
      /* return a complete screen object */
      return (id) => {
        let screen = getters.screens.find((i) => i.id == id) || null;
        if (screen) {
          screen = {...screen}; // clone to not inherit state reference
          screen.template = getters.template(id);
        }
        return screen;
      };
    },
    screenRefMap(state) {
      return (screenId) => {
        if (state.draft && state.draft.screenId == screenId) {
          return state?.draft?.template?.draft?.refMap || null;
        }
        return null;
      };
    },
    template(state) {
      return (id) => {
        return (
          (state.templates && id in state.templates && state.templates[id]) ||
          null
        );
      };
    },
    property(state) {
      return (propName) => {
        let config = Vue.http.options.config || {};
        return (config && propName in config && config[propName]) || "";
      };
    },
    hasPanelExpanded(state) {
      return state.expandedPanel != "";
    },
    expandedPanel(state) {
      return state.expandedPanel;
    },
    fullscreenPanel(state) {
      return state.fullscreenPanel;
    },
    initialPanelTemplate(state) {
      // returns initial template for a panel
      return (name) => {
        let panel = (Panels || []).find((p) => p.name == name) || null;
        return (panel && panel.template) || null;
      };
    },
    allPanels() {
      return Panels || [];
    },
    draftPanel(state) {
      return (entry) => {
        if (state.draft && state.draft.screenId == entry.screenId) {
          return (
            (state?.draft?.template?.panels || []).find(
              (i) => i.name == entry.panelName
            ) || null
          );
        }
        return null;
      };
    },
    draft(state) {
      return state.draft;
    },
    editorPanelName(state) {
      return state.editorPanelName;
    },
    editorScreenId(state) {
      return (state.draft && state.draft.screenId) || null;
    },
    editorTemplate(state) {
      return (state.draft && state.draft.template) || null;
    },
    sidebar(state) {
      return state.sidebar;
    },
    currentDraftPanel(state) {
      return state.currentDraftPanel;
    },
    currentStaticPanel(state) {
      if (!state?.currentDraftPanel?.template) return null;
      return (
        Panels.find(
          (panel) => panel.template.template == state.currentDraftPanel.template
        ) || null
      );
    },
    extendedProperties(state, getters, rootState, rootGetters) {
      if (!state.listUpdateCounter && !state.connectorValueSync) return [];
      const cfg = Vue?.http?.options?.config || {};
      let extendedProperties = cfg?.data_extended_properties || [];
      // once equipment_extended_property is a customer choice, make them always available for selection

      const $info = (connector, getters, asDefault) => {
        const prop = "$info";
        let id = asDefault
          ? `connector_${prop}`
          : `connector_${connector.id}_${prop}`;
        return {
          id,
          connector_id: connector.id,
          name: prop,
          read_only: true,
          reference_id: id,
          history_enabled: false,
          device: {
            id: -1,
            name: "connector",
            connector: {
              id: connector.id,
              name: "connector_property"
            }
          },
          current_value: {
            value: ""
          },
          // Attach the connector.$info property to your your synoptic control (data_id)
          // at the expression field, you might use the following strings ${data.device_count}
          get device_count() {
            return getters.deviceList.filter(
              ({connector_id, enabled}) =>
                enabled && parseInt(connector_id) == parseInt(this.connector_id)
            ).length;
          },
          get data_count() {
            return getters.dataList.filter(
              ({clp_id, enabled}) =>
                enabled && parseInt(clp_id) == parseInt(this.connector_id)
            ).length;
          },
          get alarm_count() {
            return getters.alarmList.filter(
              ({connector_id, enabled}) =>
                enabled && parseInt(connector_id) == parseInt(this.connector_id)
            ).length;
          }
        };
      };

      for (var p in cfg?.equipment_extended_properties || {}) {
        if (p == "notifications") continue;
        if (!extendedProperties.some(({name}) => name == p)) {
          extendedProperties.push(`connector_${p}`);
        }
      }

      let list = [],
        dashboardConnector =
          getters.mode == "viewer" ? getters.dashboardEquipment : null;
      const tc = window.app.__vue__.$tc.bind(window.app.__vue__);

      if (getters.connectorList?.length && getters.mode == "editor") {
        list.push({
          id: "connector_group",
          name: "connector_property",
          current_value: {
            date_time: new Date().toISOString(),
            id: "connector_group",
            value: ""
          },
          read_only: true,
          reference_id: "connector_group",
          history_enabled: false,
          device: {
            id: -1,
            name: "connector",
            connector: {
              id: -1,
              name: "connector_property"
            }
          }
        });
      }

      getters.connectorList?.forEach?.((connector) => {
        let props = [];
        //===============================
        let model_props = [];
        if (getters.mode == "editor") {
          if (connector.base_model) {
            model_props = (
              connector?.portal_data?.extended_properties || []
            ).map(({name}) => `connector_${name}`);
          }
        } else {
          if (connector.base_model_id) {
            model_props = Object.keys(
              connector?.user_data?.extended_properties || {}
            ).map((name) => `connector_${name}`);
          }
        }
        let id = "";
        let prop = "";
        for (prop in connector) {
          let foundInModel =
            model_props.length && model_props.indexOf(`connector_${prop}`) >= 0;
          let foundInContract =
            extendedProperties.length &&
            extendedProperties.indexOf(`connector_${prop}`) >= 0;
          //===============================
          id = `connector_${connector.id}_${prop}`;
          if (
            !(connector[prop] instanceof Object) &&
            (foundInModel || foundInContract)
          ) {
            // properties from this specific connector
            props.push({
              id,
              name: prop,
              current_value: {
                date_time: new Date().toISOString(),
                id,
                value: connector[prop]
              },
              read_only: true,
              reference_id: id,
              history_enabled: false,
              device: {
                id: -1,
                name: "connector",
                connector: {
                  id: connector.id,
                  name: "connector_property"
                }
              }
            });
            if (dashboardConnector && connector.id == dashboardConnector.id) {
              // add connector property as current connector opened equipment
              props.push({
                id: `connector_${prop}`,
                name: prop,
                current_value: {
                  date_time: new Date().toISOString(),
                  id: `connector_${prop}`,
                  value: connector[prop]
                },
                read_only: true,
                reference_id: `connector_${prop}`,
                history_enabled: false,
                device: {
                  id: -1,
                  name: "connector",
                  connector: {
                    id: connector.id,
                    name: "connector_property"
                  }
                }
              });
            }
          }
        }
        // add connector info properties
        props.push($info(connector, getters));
        if (dashboardConnector && connector.id == dashboardConnector.id) {
          props.push($info(connector, getters, true));
        }
        // concat sorted props
        list = list.concat(
          props.sort((a, b) =>
            new Intl.Collator(rootGetters.locale, {
              sensitivity: "base",
              numeric: true
            }).compare(tc(a.name.trim()), tc(b.name.trim()))
          )
        );
      });

      getters.deviceList.forEach((device, index) => {
        if (getters.mode == "editor") {
          list.push({
            id: "device_group_" + index,
            name: "device_property",
            current_value: {
              date_time: new Date().toISOString(),
              id: "device_group",
              value: ""
            },
            read_only: true,
            reference_id: "device_group",
            history_enabled: false,
            device: {
              id: -1,
              name: device.name,
              connector: {
                id: device?.connector_id || device?.connector?.id || -1,
                name: "device_property"
              }
            }
          });
        }
        let props = [];
        for (let prop in device) {
          let prop_id = "device_" + prop,
            id = `device_${
              device.connector?.base_model ? device.reference_id : device.id
            }_${prop}`;
          if (
            !(device[prop] instanceof Object) &&
            (extendedProperties.length
              ? extendedProperties.indexOf(prop_id) >= 0
              : true)
          ) {
            props.push({
              id,
              name: prop,
              current_value: {
                date_time: new Date().toISOString(),
                id,
                value: device[prop]
              },
              read_only: true,
              reference_id: id,
              history_enabled: false,
              device: {
                id: (index + 1) * -1,
                name: device.name,
                connector: {
                  id: device?.connector_id || device?.connector?.id || -1,
                  name: "device_property"
                }
              }
            });
          }
        }
        // concat sorted props
        list = list.concat(
          props.sort((a, b) =>
            new Intl.Collator(rootGetters.locale, {
              sensitivity: "base",
              numeric: true
            }).compare(tc(a.name.trim()), tc(b.name.trim()))
          )
        );
      });
      return list;
    },
    connectorList(state) {
      return state.connectorList || [];
    },
    deviceList(state) {
      if (!state.listUpdateCounter) return [];
      return Object.keys(state.connectorsResources).reduce((list, id) => {
        list = list.concat(state.connectorsResources[id].deviceList ?? []);
        return list;
      }, []);
    },
    deviceListFrom(state) {
      return (connectorId) =>
        state.connectorsResources[connectorId]?.deviceList;
    },
    // <resource>ListFromEquipment = resource list from connector currently open on dashboard
    deviceListFromEquipment(state) {
      return state.connectorsResources[state.dashboardEquipmentId]?.deviceList;
    },
    dataList(state) {
      if (!state.listUpdateCounter) return [];
      return Object.keys(state.connectorsResources).reduce((list, id) => {
        list = list.concat(state.connectorsResources[id].dataList ?? []);
        return list;
      }, []);
    },
    extendedDataList(state, getters) {
      if (!state.listUpdateCounter) return [];
      return getters.dataList.concat(getters.extendedProperties);
    },
    dataListFrom(state) {
      return (connectorId) => state.connectorsResources[connectorId]?.dataList;
    },
    dataListFromEquipment(state) {
      return state.connectorsResources[state.dashboardEquipmentId]?.dataList;
    },
    alarmList(state) {
      if (!state.listUpdateCounter) return [];
      return Object.keys(state.connectorsResources).reduce((list, id) => {
        list = list.concat(state.connectorsResources[id].alarmList ?? []);
        return list;
      }, []);
    },
    alarmListFrom(state) {
      if (!state.listUpdateCounter) return [];
      return (connectorId) => state.connectorsResources[connectorId]?.alarmList;
    },
    alarmListFromEquipment(state) {
      if (!state.listUpdateCounter) return [];
      return state.connectorsResources[state.dashboardEquipmentId]?.alarmList;
    },
    dashboardEquipmentId(state) {
      return state.dashboardEquipmentId;
    },
    dashboardScreenId(state) {
      return state.dashboardScreenId;
    },
    dashboardEquipment(state) {
      return (
        (state.dashboardEquipmentId &&
          state.connectorList?.find?.(
            ({id}) => parseInt(state.dashboardEquipmentId) === parseInt(id)
          )) ||
        null
      );
    },
    hasResourceFrom(state) {
      return (resource, connectorId) =>
        state.tasks.some(
          (task) =>
            task.resource == resource &&
            task.query == connectorId &&
            !Object.keys(omit(task.filters, "update")).length
        );
    },
    // task list without duplicates
    uniqueTasks(state) {
      return Object.entries(
        state.tasks.reduce(
          (tasks, task) =>
            !tasks[
              `${task.resource}-${JSON.stringify(task.query) +
                (task.filters ? "-" + JSON.stringify(task.filters) : "")}`
            ]
              ? {
                  ...tasks,
                  [`${task.resource}-${JSON.stringify(task.query) +
                    (task.filters
                      ? "-" + JSON.stringify(task.filters)
                      : "")}`]: task
                }
              : tasks,
          {}
        )
      ).map(([, v]) => v);
    },
    // unique tasks which results contain resources from tasks with filters
    genericUniqueTasks(state, getters) {
      return getters.uniqueTasks.filter((task, _, list) => {
        let matchingGenericTask = list.find(
          (t) =>
            t.resource == task.resource &&
            isEqual(t.query, task.query) &&
            !t.filters
        );
        if (task.filters && matchingGenericTask) {
          return false;
        }
        return true;
      });
    },
    sessionId(state) {
      return state.sessionId;
    },
    clipboard(state) {
      return state.clipboard;
    },
    applicableStyle(state) {
      return state.applicableStyle;
    },
    // extendedProperties(state, getters, rootState, rootGetters) {
    manualRefresh(state) {
      return state.manualRefresh;
    },
    editorSettings(state) {
      let cfg = editorSettings();
      let recent =
        ((cfg || {})?.recent || []).filter(({screenId}) =>
          ((state && state?.screens) || []).some(
            ({id}) => parseInt(id) == parseInt(screenId)
          )
        ) || [];
      return {
        ...{_: state.settingUpdateCounter},
        ...{...cfg, recent: recent}
      };
    },
    connectorsResources(state) {
      return state.connectorsResources;
    },
    controlDataSelectorSource(state) {
      return state.controlDataSelectorSource; // connectorId
    },
    dataValueSync(state) {
      return state.dataValueSync;
    },
    connectorValueSync(state) {
      return state.connectorValueSync;
    },
    defaultPanelConfiguration() {
      let config = (Vue.http.options && Vue.http.options.config) || {};
      let name = config?.default_panel || "blank_panel";
      let template =
        (Panels || []).find((p) => p.name == name)?.template?.template ||
        "EquipmentEmptyPanel";
      return {name, template};
    },
    getRecentScreenId(state, getters) {
      return (
        ((getters.editorSettings || {})?.recent || []).find(({screenId}) =>
          ((state && state?.screens) || []).some(
            ({id}) => parseInt(id) == parseInt(screenId)
          )
        )?.screenId || ""
      );
    },
    dataDisplayLabel(state) {
      let entry = editorSettings();
      return state.dataDisplayLabel || entry.dataDisplayLabel || "name";
    },
    showDeletedScreens(state) {
      let entry = editorSettings();
      if (state.showDeletedScreens === null) return entry.showDeletedScreens;
      return state.showDeletedScreens;
    },
    dragging(state) {
      return state.dragging;
    },
    simulation(state) {
      return state.simulation;
    },
    hasPendingChanges(state) {
      return Object.keys(state.dirtyForms || {}).some(
        (key) => state.dirtyForms[key]
      );
    }
  }
};
